import React, { useState, useEffect, useContext, useRef } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Header, Footer, AnnonceCard, Loading } from '../components/imports'

import Func from '../class/Func';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { CarburantType, Marques, NbrPlaces, Transmission } from '../components/generalImports';

function Search() {

    const [get_params] = useSearchParams()

    const searchRef = useRef(null)

    const [locationLat, setLocationLat] = useState(get_params.get("lat"))
    const [locationLng, setLocationLng] = useState(get_params.get("lng"))

    const [date, setDate] = useState({
        startDate: new Date(get_params.get("startDate")),
        endDate:  new Date(get_params.get("endDate")),
        key: 'selection',
    })

    const [openDate, setOpenDate] = useState(false)
    const [articles, setArticles] = useState('')

    const sortRef = useRef(null)
    const searchFormRef = useRef(null)
    const [key, setKey] = useState(0)

    const navigate = useNavigate()

    let adresse = get_params.get("adresse")
    let start_date = get_params.get("startDate")
    let end_date = get_params.get("endDate")
    let geo_lat = get_params.get("lat")
    let geo_long = get_params.get("lng")
    
    const onChangeAddress = (autocomplete) => {

        const place = autocomplete.getPlace()
        
        setLocationLat(place.geometry.location.lat())
        setLocationLng(place.geometry.location.lng())

    }

    const handleOpenDate = () => {
        setOpenDate((prev) => !prev)
    }

    const handleSelectDates = (ranges) => {
        setDate(ranges.selection)
    }

    const rechercher = async() => {
        
        const results = await Func.fetch("annonces/search", {geo_lat: locationLat, geo_long: locationLng, start_date: date.startDate, end_date: date.endDate, options: {}}, false)
        
        if(results.success){
            
            setArticles(results.data)

        }else{

            setArticles(results.message)

        }

    }

    const sortBy = (e) => {

        let sortArticles = []

        if(e.target.value == "lowPrice"){
            sortArticles = articles.sort((a, b) => a.prix - b.prix);
        }else{
            if(e.target.value == "hightPrice"){
                sortArticles = articles.sort((a, b) => b.prix - a.prix);
            }else if(e.target.value == "distance"){
                //par date a ajouter
                sortArticles = articles.sort((a, b) => a.distance - b.distance);
            }
        }

        setKey(currentKey => currentKey+1)
        setArticles(sortArticles)
        
    }

    const filter = async (e) => {

        e.preventDefault()

        let input = e.target

        let options = {

            minPrix: input.minPrix.value,
            maxPrix: input.maxPrix.value,
            categorie: input.categorie.value,
            marque: input.marque.value,
            annee: input.annee.value,
            nbrPlaces: input.nbrPlaces.value,
            carburant: input.carburant.value,
            transmission: input.transmission.value

        }

        // console.log(options)

        setArticles('')

        const results = await Func.fetch("annonces/search", {geo_lat: locationLat, geo_long: locationLng, start_date: date.startDate, end_date: date.endDate, options}, false)
        
        console.log(results)

        if(results.success){
            
            setArticles(results.data)

        }else{

            setArticles(results.message)

        }

    }

    useEffect(() => {
        
        searchRef.current.value = adresse

        Func.initMapScript().then(() => Func.initMapPlaces(searchRef.current, onChangeAddress))

        rechercher()

    }, [])

    return (
        
        <div>

            <Header />

                <div className='search content'>
                    <div className="srch mb30 c">

                        <form action="search" method="GET" ref={searchFormRef} id='searchForm' className='bg1 pl10 pr10 flex jc-between m0a shad col-10 flex br30'>
                            <input name='startDate' type="text" className='d-none' value={moment(date.startDate).format('YYYY-MM-DD')}/>
                            <input name='endDate' type="text" className='d-none' value={moment(date.endDate).format('YYYY-MM-DD')}/>
                            <input name='lat' type="text" className='d-none' value={locationLat}/>
                            <input name='lng' type="text" className='d-none' value={locationLng}/>
                                           
                            <div className="flex jc-start ai-center col-38">
                                <span className="icon cl6 ri-map"></span>
                                <label htmlFor="adresse">
                                    <p className='cl6'>Lieu de ramassage</p>
                                    <input ref={searchRef} name='adresse' id='adresse' type="text" placeholder="Adresse précise"/>
                                </label>
                            </div>

                            <div className="flex jc-start ai-center col-25">
                                <span className="icon cl6 ri-calendar"></span>
                                <div className='label' onClick={handleOpenDate}>
                                    <p className='cl6'>Récupérer le</p>
                                    <span className='input fw5'>{moment(date.startDate).format('ddd Do MMM')}</span> 
                                </div>
                            </div>

                            <div className="flex jc-start ai-center col-25">
                                <span className="icon cl6 ri-calendar"></span>
                                <div className='label' onClick={handleOpenDate}>
                                    <p className='cl6'>Déposer le</p>
                                    <span className='input fw5'>{moment(date.endDate).format('ddd Do MMM')}</span> 
                                </div>
                            </div>

                            <button className="icon bg3 cl1 p20 br30 ri-search brc0"></button>
                            
                            {openDate && <div className='calendar'>
                                <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleSelectDates}
                                    months={2}
                                    minDate={new Date()}
                                />
                            </div>}
                        </form>

                    </div>

                    <div className="flex c ai-start">

                        <form method="POST" onSubmit={e => filter(e) } className="filter col-2 brc1solid brc9 br15 shad p20 ">
                            <span className='fw6 d-block mb20'>Filtrer par</span>
                            
                            <div className='gla-form'>
                                
                                <div>

                                    <label className='mb10 fw5 fz09'>Fourchette de prix</label>

                                    <div className='grid2'>
                                        <input name='minPrix' type="number" placeholder="Min" min={0}/>
                                        <input name='maxPrix' type="number" placeholder="Max" min={0}/>
                                    </div>

                                </div>

                                <div>
                                    <label>Catégorie</label>
                                    <select name="categorie" >
                                        <option selected value=''>Toutes</option>
                                        <option value="1">Citadine</option>
                                        <option value="2">Berline</option>
                                        <option value="3">Mini</option>
                                        <option value="4">SUV</option>
                                    </select>
                                </div>


                                <div>
                                    <label >Marque du véhicule</label>
                                    <select name="marque"  id='marque'>
                                        <option selected value=''>Toutes</option>
                                        {Marques.map(element =>
                                            <option value={element.value}>{element.label}</option>
                                        )}
                                    </select>
                                </div>

                                <div className='grid2'>
                                    <div>
                                        <label>Année</label>
                                        <select name="annee" >
                                            <option selected value=''>Toutes</option>
                                            {Func.generateYears().map(element => 
                                                <option value={element.value}>{element.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Places</label>
                                        <select name="nbrPlaces" >
                                            <option selected value=''>Aucun choix</option>
                                            {NbrPlaces.map(element =>
                                                <option value={element.value}>{element.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label>Type de carburant</label>
                                    <select name="carburant" >
                                        <option selected value=''>Tout</option>
                                        {CarburantType.map(element => 
                                            <option value={element.value}>{element.label}</option>
                                        )}
                                    </select>
                                </div>

                                <div>
                                    <label>Transmission</label>
                                    <select name="transmission" >
                                        <option selected value=''>Toutes</option>
                                        {Transmission.map(element => 
                                            <option value={element.value}>{element.label}</option>
                                        )}
                                    </select>
                                </div>
                                
                                <div className='flex jc-end'>  
                                    <button className="bg3 cl1 btn2 brc0">Filtrer</button>
                                </div>

                            </div>

                        </form>

                        <div className="col-78">

                            <span className="mb10 fw5 d-block fz13"> {adresse}</span>
                            <div className='flex jc-between ai-center'>
                                {/* <p className='mb30 cl2 fw5'>{articles.length} Véhicules trouvés</p> */}
                                <div className='gla-form'>
                                    <select name="sort" id='sortDrop' onChange={sortBy}>
                                        <option disabled>Trier par</option>
                                        <option value="distance">Plus proche</option>
                                        <option value="lowPrice">Tarif (le - cher en premier)</option>
                                        <option value="hightPrice">Tarif (le + cher en premier)</option>
                                    </select>
                                </div>
                            </div>

                            <div className="articles grid3" key={key}>
                                
                                {

                                    articles

                                    ?

                                        typeof articles == 'object'

                                        ?

                                        articles.map(element => <AnnonceCard data={element} key={element.idA} />)
                                        
                                        :
                                        
                                        <p>{ articles }</p>

                                    :

                                    <Loading />

                                }

                            </div>

                        </div>
                    </div>
                </div>

            <Footer />

        </div>
    )

}

export default Search