import { API_ROOT, API_SECRET, AVATAR_DEFAULT, AVATAR_ROOT, MAPS_API_KEY, MAPS_API_ROOT } from '../constants/general';

class Func {

    static fetch(url, vars, auth = true){
        let obj
        
        if(auth){

            obj = {
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            }

        }else{

            obj = {
                api_secret: API_SECRET
            }

        }

        let body = {...obj, ...vars}

        let data = fetch(API_ROOT + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static fetch2(url, vars, auth = true){
        let obj
        
        if(auth){

            obj = {
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            }

        }else{

            obj = {
                api_secret: API_SECRET
            }

        }

        let body = {...obj, ...vars}

        let data = fetch(API_ROOT + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
               
        }).then(function (response) {
            return response.text()
        }).then(function (data) {
            console.log(data)
            return data

        })

        return data

    }

    static session(context, navigate) {
             
        if((context.session !== true)) navigate('/connexion') 

    }

    static setFlash(message, type = "succes") {
             
        localStorage.setItem('message', message)
        localStorage.setItem('message_type', type)

    }

    static getFlash(setFlash) {
             
        if (localStorage.getItem('message') !== undefined && localStorage.getItem('message_type') !== undefined) {

            let message = localStorage.getItem('message')
            let message_type = localStorage.getItem('message_type')
            
            setFlash({"message" : message, "type" : message_type})

            localStorage.removeItem('message')
            localStorage.removeItem('message_type')


        }

    }

    static logout = (context, navigate) => {

        context.setSession(false)

        localStorage.removeItem('idU');
        localStorage.removeItem('app_token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('avatar');

        navigate('/')   

    }

    static saveSession(data, setSession) {
             
        localStorage.setItem('idU', data.idU);
        localStorage.setItem('app_token', data.app_token);
        localStorage.setItem('name', data.name);
        localStorage.setItem('email', data.email);
        localStorage.setItem('avatar', data.avatar);

        setSession(true)

    }

    static openMenu(){

        document.body.classList.toggle('mn')
    
    }

    static choixSelect(element){

        let radios = document.querySelectorAll(element)
    
        radios.forEach(element => {

            element.addEventListener('click', function(el){

                radios.forEach((elem) => elem.querySelector('span').classList.remove('bg3'))

                this.querySelector('span').classList.add('bg3')
                this.querySelector('input').click()

            })

        })
      
    }

    static avatar(avatar){

        if(avatar == 0){

            return AVATAR_DEFAULT

        }else{

            return AVATAR_ROOT + avatar + '.jpg'
            
        }
    
    }

    static generateYears() {
        const endDate = new Date().getFullYear();
          let years = [];
          let startYear = 2000
    
          for (var i = startYear; i <= endDate; i++) {
            years.push({ label: startYear.toString(), value: startYear.toString() });
            startYear++;
          }
          return years
    }

    //----------- Google Maps Places

    static loadScript (src) {
        return new Promise( resolve => {
            const script = document.createElement("script")
            Object.assign(script, {
            type: "text/javascript",
            async: true,
            loading: 'async',
            src
            })
            script.addEventListener("load", () => resolve(script))
            document.head.appendChild(script)
        })
    }

    static initMapScript ()  {
        if(window.google){
            return Promise.resolve()
        }
        const src = `${MAPS_API_ROOT}?key=${MAPS_API_KEY}&libraries=places`
        
        return this.loadScript(src)

    }

    static initMapPlaces (input, onChange){
        if(!input) return;
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        autocomplete.setFields(["address_component", "geometry", "name"]);
        autocomplete.addListener("place_changed", () => onChange(autocomplete));
    }
    
}

export default Func