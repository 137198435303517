import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import { WEBROOT, APP_NAME } from '../constants/general'

import { Context } from '../class/Context'

import User from '../class/User'

const Login = async (e, setError, navigate, setSession) => {

    e.preventDefault()

    let inputs = e.target

    let email = inputs.email

    let pass = inputs.pass

    if( email.value !== '' && pass.value !== ''){

        const results = await User.login(inputs)

        console.log(results)

        if(results.success){

            localStorage.setItem('idU', results.data.idU);
            localStorage.setItem('app_token', results.data.app_token);
            localStorage.setItem('name', results.data.name);
            localStorage.setItem('email', results.data.email);
            localStorage.setItem('avatar', results.data.avatar);
                   
            setError('')
            
            navigate('/mon-espace')

            setSession(true)

         }else{
            
            setError(results.message)
                  
         }
  
    }else{

        setError( 'Assurez-vous que tout les champs sont remplis')

    }

}

function ConnexionEmail() {

    const context = useContext(Context)

    const navigate = useNavigate()

    const [error, setError] = useState('')

    useEffect(() => {

        document.title = "Connexion - " + APP_NAME

    }, [])

    return (

        <div className="login flex row-10">

            <div className="col-4 side bg5 p50 pos-r row-10 flex flex-col">
                
                <div>
                    <Link to={"/"} className="mb50 d-block"><img src="image/large/logo.png" alt={APP_NAME} className="logo mr20" /></Link>
                    
                    <p className="mb20 fz12">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur cupiditate nam!</p>

                    <p className="mb10"><span className="icon cl4 mr10 ri-shield-check-fill"></span>Tempore necessitatibus facere sapiente!</p>
                    <p className="mb10"><span className="icon cl4 mr10 ri-shield-check-fill"></span>Adipisicing elit. Consequuntur cupiditate</p>

                </div>

                <img src="image/large/login-pic.png" className="img col-8 m0a" />

            </div>

            <div v className="col-6 main row-10 p30">

                <div className="ta-end mb50">
                    <p>Vous n'êtes pas inscris ? <Link to={"/inscription"} className="cl3">Créez un compte!</Link></p>
                </div>

                <div className="col-6 m0a">

                    <h2 className="mb30 fz2 cl2">Se connecter</h2>
                    <p className="fz09 mb30 cl6">Connectez-vous en utilisant vos identifiants</p>

                    {(error !== '') && <p className="error">{error}</p>}  

                    <form action="" method="POST" className="gla-form" onSubmit={(e) => Login(e, setError, navigate, context.setSession)}>

                        <label className="mb10">Email ou username</label>
                        <input type="text" name="email" placeholder="Email" required={true} />
                        
                        <label className="mb30">Mot de passe</label>
                        <input type="password" name="pass" placeholder="******" required={true} />
                        
                        <Link to={"/motdepasse-oublie"} className="fz09 cl4 fw5 d-block ta-end">Mot de passe oublié?</Link>

                        <input type="submit" name="log" value="Connexion" className="btn bg3 cl1 brc1solid brc3" />
                        
                    </form>

                    <div className="divider mt50 mb30"></div>

                    <div className="btn_social_connect grid2 mb30">
                        <a href="" className="btn_fa bg5"><span className="icon ri-facebook-fill"></span> Facebook</a>
                        <a href="" className="btn_go bg5"><span className="icon ri-google-fill"></span> Google</a>
                    </div>
                </div>

            </div>

        </div>
        
    )

}

export default ConnexionEmail