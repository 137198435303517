import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Header, Footer } from '../components/imports'

import { APP_NAME } from '../constants/general'

function Page_404() {

    useEffect(() => {

        document.title = "Page introuvable, Erreur 404 - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div className="rent-car">

                <div className="bg5 pb100">
                    <div className="c ta-center">

                        <div className=" col-6 flex ai-center jc-center flex-col pt50 pb50 m0a">

                            <h1 className="h1 fade anim_left fade_in mb30"><span className="cl2 fz2 fw7">Page introuvable</span><span className='cl3 d-block fz13'>Erreur 404</span></h1>

                            <p className='mb40'>Impossible d'afficher cette page</p>

                            <Link to={'/'} className="btn2 brc4 brc2solid cl1 d-in-block bg4">Retour a l'acceuil</Link>

                        </div>

                    </div>
                </div>

                <div className="b1">

                    <img src="image/large/rent-my-car.png" alt='' />

                </div>

            </div>

            <Footer />

        </div>
    )

}

export default Page_404