import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { APP_NAME, AVATAR_ROOT } from '../../constants/general'

import { Context } from '../../class/Context'

import User from '../../class/User'

import Func from '../../class/Func'

import { Menu } from '../../components/espace-membre';

function Avatar() {

    const context = useContext(Context)

    const navigate = useNavigate()

    const [flash, setFlash] = useState('')

    const [loading, setLoading] = useState(false)

    const uploadAvatar = async (e) => {

        let file = e.target.files[0]

        if(file.size == 0 || file.name == null) setFlash({ type: 'error', message: "Impossible d'ajouter cette photo une erreur est servenu" })
    
        setLoading(true)
    
        let results = await User.photoUpload(file)
    
        setLoading(false)

        if (results.success && results.avatar !== undefined){
    
            localStorage.setItem('avatar', results.avatar)

            document.querySelector('.avatar_img').src = AVATAR_ROOT + results.avatar

            setFlash('')

        }else{
    
            setFlash({type: 'error', message: results.message})
            
        }
    
    }

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Ma photo de profile - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c">

                    <Menu data="photo-de-profil"/>

                    <div className="cont col-78">
                        
                        <h2>Ma photo de profil</h2>

                        {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}

                        <label className="flex ai-center">

                            <div className="flex ai-center">

                                {
                                        
                                    loading

                                    ?

                                    <div className="loader"></div>

                                    :

                                    <div className="av medium mr20 mt30">

                                        <img src={ Func.avatar(localStorage.getItem('avatar')) } className="avatar_img"/>
                                        
                                        <input type="file" id="uploadPhotosInput" accept="image/png, image/gif, image/jpeg" onChange={e => uploadAvatar(e)} style={{ display: 'none'}}/>
                                        
                                    </div>

                                }

                                <span className="btn2 bg4 cl1 brc1solid brc4 mt0">Modifier ma photo de profile</span>

                            </div>

                        </label>

                    </div>
                </div>
            </div>

            <Footer/>

        </div>

    )

}

export default Avatar