import React, { useEffect, useState } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import { Header, Footer } from '../components/imports'

import { APP_NAME } from '../constants/general'

import User from '../class/User';

function EnvoyerMessage() {

    const { id } = useParams()

    const navigate = useNavigate()

    const [error, setError] = useState('')
    
    const Envoyer = async () => {

        if(localStorage.getItem('idU') == undefined) setError("Connectez vous pou renvoyer des messages")

        if(localStorage.getItem('idU') == id) navigate('/mon-espace')

        let results = await User.envoyerMessage(id)

        if(results.success){

            navigate('/mon-espace/discussion/' + results.discussion_id)

        }else{

            setError(results.message)
            
        }

    }

    useEffect(() => {

        Envoyer()

        document.title = "Envoyer un message - " + APP_NAME
        
    }, [])


    return (
        <div>

            <Header />

            <div className="single content">

                <div className="cc">
                    <div className="c-infos">

                        <h1 className="mb10">Envoyer un message</h1>

                        <div className='flex ai-center jc-center'>

                            {

                                error !== ''

                                ?

                                    <p className='error'>{ error }</p>

                                :

                                    <div className='loader'></div>

                            }

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )

}

export default EnvoyerMessage