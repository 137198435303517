import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Header } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import { DiscussionsCard } from '../../components/espace-membre';

function Discussions() {

    const navigate = useNavigate();

    const context = useContext(Context)

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Mes discussions - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c">

                <DiscussionsCard />

                <div className="messages_box col-7">
                    
                    <div className='messages bg5 flex ai-center jc-center'>
                        
                        <p className='rem'>Veuillez sélectionner une discussion pour afficher les messages</p>

                    </div>

                </div>
                </div>
            </div>

        </div>

    )

}

export default Discussions