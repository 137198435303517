import React, { useEffect, useState } from 'react';

import { useParams, useNavigate} from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { APP_NAME } from '../../constants/general'
import Func from '../../class/Func';
import Reservations from '../../class/Reservations';
import moment from 'moment';
import 'moment/locale/fr'

function Contrat() {

    const { id } = useParams()

    const navigate = useNavigate()

    const [error, setError] = useState(false)

    const [reservation, setReservation] = useState(false)

    const [client, setClient] = useState(false)
    const [proprietaire, setProprietaire] = useState(false)

    const [contrat, setContrat] = useState(false)

    const getReservation = async () => {

        const results = await Reservations.reservation(id)

        if(results.success){

            setReservation(results.data)

            setClient(results.client)

            setProprietaire(results.proprietaire)
            
        }else{

            navigate('/404')

        }

    }

    const getContrat = async () => {

        const contrat = await Func.fetch("reservations/contratInfosByReservation", {id})

        if(contrat.success){

            setContrat(contrat.contrat)

        }

    }

    const enregistrer = async (e) => {

        e.preventDefault()

        let input = e.target

        let infos = {

            naissanceLocataire: input.naissanceLocataire.value,
            NumCarte: input.NumCarte.value,
            NumPermis: input.NumPermis.value,
            marque: input.marque.value,
            matricule: input.matricule.value,
            annee: input.annee.value,
            adrVehicule: input.adrVehicule.value,
            etat: input.etat.value,
            kilometres: input.kilometres.value,
            carburant: input.carburant.value,
            niveauCarburant: input.niveauCarburant.value

        }

        const results = await Func.fetch("reservations/creerContrat", {id, infos})

        if(results.success){
            
            // Redirect

            let url = '/mon-espace/finaliser-contrat/' + results.contrat_id + '?reservation_id='+ id

            if(results.created) url += '&created=true'

            navigate(url)


        }else{

            setError(results.message)

        }

    }

    useEffect(() => {
        
        document.title = "Contrat de location d'un véhicule - " + APP_NAME

        getReservation()

        getContrat()
        
    }, [])


    return (
        <div>
            <Header />
            
            <div className='content dashboard'>

                <div className='col-5 m0a bg5 p20 br20'>
                    <h2 className='mb30'>Contrat de location</h2>

                    {(error !== false) && <p className="error">{error}</p>}  

                    <form className="gla-form" method='POST' onSubmit={ (e) => enregistrer(e) }>

                        <span className='d-block mb20 cl6 fw6'>Informations sur le locataire</span>

                        <div className='grid2'>
                            <div >
                                <label>Nom et Prénom<span className='cl4'>*</span></label>
                                <input type="text" name="locataire" placeholder="Nom et Prénom" required={true} defaultValue={reservation.name} disabled/>
                            </div>
                            <div >
                                <label>Adresse<span className='cl4'>*</span></label>
                                <input type="text" name="adrLocataire" placeholder="Adresse du locataire ..." required={true}  defaultValue={client.adresse} disabled/>
                            </div>
                        </div>

                        <div className='grid2'>
                            <div >
                                <label>Date de naissance<span className='cl4'>*</span></label>
                                <input type="date" name="naissanceLocataire" required={true} defaultValue={contrat ? contrat.naissanceLocataire : null}/>
                            </div>

                            <div>
                                <label>N° carte d'identité<span className='cl4'>*</span></label>
                                <input type="text" name="NumCarte" placeholder="N° carte d'identité ..." required={true} defaultValue={contrat ? contrat.NumCarte : null}/>
                            </div>
                            
                        </div>

                        <div className='grid2'>
                            
                            <div >
                                <label>N° permis<span className='cl4'>*</span></label>
                                <input type="text" name="NumPermis" placeholder="N° permis ..." required={true}  defaultValue={contrat ? contrat.NumPermis : null}/>
                            </div>

                            <div>
                                <span className='fz07'>J'atteste avoir vérifié le permis de conduire (le permis B en cours de validité et obtenu depuis plus de 3 ans) et la pièce d'identité du locataire et des éventuels conducteurs supplémentaires et avoir reçu descopies ou des photos</span>
                            </div>
                        </div>

                        <span className='d-block mb20 cl6 fw6'>Informations sur le propriétaire</span>

                        <div className='grid2'>
                            <div>
                                <label>Nom et Prénom<span className='cl4'>*</span></label>
                                <input type="text" name="proprietaire" placeholder="Nom et Prénom" required={true} defaultValue={localStorage.getItem('name')} disabled/>
                            </div>
                            <div>
                                <label>Adresse<span className='cl4'>*</span></label>
                                <input type="text" name="adrProprietaire" placeholder="Adresse du proprietaire ..." required={true}  defaultValue={proprietaire.adresse} disabled/>
                            </div>
                        </div>

                        <div className='brc1Top brc6 pt20 pb20'>
                            <span className='d-block mb20 cl6 fw6'>Détail de location</span>

                            <div className='flex mb10'>
                                <p className='col-25 fw5'>Date de Début</p>
                                <p className='col-78'>{moment(reservation.start_date).format('LL')}</p>
                            </div>

                            <div className='flex mb10'>
                                <p className='col-25 fw5'>Date de fin</p>
                                <p className='col-78'>{moment(reservation.end_date).format('LL')}</p>
                            </div>

                            <div className='flex mb10'>
                                <p className='col-25 fw5'>Inclus</p>
                                <p className='col-78'>150 km (13.00 Da/km au delà)</p>
                            </div>

                            <div className='flex mb10'>
                                <p className='col-25 fw5'>Prix total</p>
                                <p className='col-78'>{reservation.price} Da(hors frais de service)</p>
                            </div>

                            <div className='flex mb10'>
                                <p className='col-25 fw5'>Franchise</p>
                                <p className='col-78'>30 000 Da*</p>
                            </div>

                            <div className='flex mb10'>
                                <p className='col-25 fw5'>Rachat de franchise</p>
                                <p className='col-78'>Partiel</p>
                            </div>

                            <p className='fz08'>* Cette franchise s'applique en cas d'incendie, vol, dommages tous accidents, évènements climatiques et  attentats. D'autres franchises peuvent s'ajouter en cas de conducteur novice et/ou non dénnomé au contrat et dans le cas de détournement/non-restitution du véhicule.</p>
                        </div>

                        <div className='brc1Top brc6 pt20 pb20'>

                            <span className='d-block mb20 cl6 fw6'>État du véhicule</span>

                            <div className='grid2'>
                                <div>
                                    <label>Marque et Modèle du véhicule<span className='cl4'>*</span></label>
                                    <input type="text" name="marque" placeholder="Marque et Modèle" required={true}  defaultValue={contrat ? contrat.marque : null}/>
                                </div>
                                <div>
                                    <label>Immatriculation du véhicule<span className='cl4'>*</span></label>
                                    <input type="text" name="matricule" placeholder="Immatriculation du véhicule" required={true}  defaultValue={contrat ? contrat.matricule : null}/>
                                </div>
                            </div>

                            <div className='grid2'>
                                <div>
                                    <label>Année du véhicule<span className='cl4'>*</span></label>
                                    <select name="annee" required={true}  defaultValue={contrat ? contrat.annee : null}>
                                    { Func.generateYears().map(element => 
                                        <option value={element.value}>{element.label}</option>
                                    )}
                                    </select>
                                </div>
                                <div>
                                    <label>Adresse du véhicule<span className='cl4'>*</span></label>
                                    <input type="text" name="adrVehicule" placeholder="Adresse du véhicule" required={true}  defaultValue={contrat ? contrat.adrVehicule : null}/>
                                </div>
                            </div>

                            <div>
                                <label>État de la voiture <span className='cl4'>*</span></label>
                                <textarea name='etat' placeholder='État général, propreté, dommages visibles, ...'  defaultValue={contrat ? contrat.etat : null}></textarea>
                            </div>

                            <div className='grid3'>
                                <div>
                                    <label>Kilomètres<span className='cl4'>*</span></label>
                                    <input type="text" name="kilometres" placeholder="Kilomètres" required={true}  defaultValue={contrat ? contrat.kilometres : null}/>
                                </div>

                                <div>
                                    <label>Carburant <span className='cl4'>*</span></label>
                                    <select name="carburant" required={true}  defaultValue={contrat ? contrat.carburant : null}>
                                        <option>Essence</option>
                                        <option>Diesel</option>
                                    </select>
                                </div>

                                <div>
                                    <label>Niveau carburant <span className='cl4'>*</span></label>
                                    <input type="text" name="niveauCarburant" placeholder="Niveau carburant en %" required={true}  defaultValue={contrat ? contrat.niveauCarburant : null}/>
                                </div>

                            </div>

                            <div className='cgu_accept'>
                                <input type="checkbox" name='controle' className='col-1' required={true} checked={contrat && true}/>
                                <p>Contrôle technique à jour</p>
                            </div>

                            <div className='cgu_accept'>
                                <input type="checkbox" name='carteGrise' className='col-1' required={true} checked={contrat && true}/>
                                <p>Le locataire à reçu la copie de la carte grise</p>
                            </div>

                            <div className='cgu_accept'>
                                <input type="checkbox" name='assurance' className='col-1' required={true} checked={contrat && true}/>
                                <p>Le locataire a reçu la copie de l'attestation d'assurance annuelle</p>
                            </div>

                            <div className='flex jc-end mt20'>
                                <input type="submit" value='Eregistrer et continuer' className='btn2 bg3 cl1 mr10 brc0'/>
                            </div>

                        </div>
                        
                    </form>


                </div>
            </div>

            <Footer />
            
        </div>
    )

}

export default Contrat