import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Header, Footer, AnnonceCard, Loading } from '../components/imports'

import { WEBROOT, APP_NAME } from '../constants/general'

import Annonces from '../class/Annonces';

function Categorie() {

    const { url } = useParams()
    
    const navigate = useNavigate()

    const [annonces, setAnnonces] = useState(false)
    const [categorie, setCategorie] = useState(false)
    
    const getData = async () => {

        let results = await Annonces.byCategorie(url)

        if(results.success){

            setAnnonces(results.annonces)
            setCategorie(results.categorie)

            document.title = results.categorie.name + " - " + APP_NAME

        }else{

            navigate('/404')

        }

    }

    useEffect(() => {

        getData()

    }, [])


    return (
        <div>

            <Header />

                <div className='content'>

                    {

                        categorie

                        ?

                        <div className='c flex jc-start ai-center'>

                            <img src={ WEBROOT + 'gla-adminer/uploads/article/small/' + categorie.image } alt={ categorie.name } className="col-1 br15 mr20"/>
                            <div className="cnt">
                                <h3>{ categorie.name }</h3>
                                <p>{ categorie.description }</p>
                            </div>

                        </div>

                        :

                        <Loading />

                    }

                    <div className="c4 c pt40 pb40">

                        <h2 className="mb20 fw5">{ categorie.description }</h2>
                        
                        <div className="articles grid4">
                            
                            {

                                annonces

                                ?

                                annonces.map(element => <AnnonceCard data={element} key={element.idA} />)

                                :

                                <Loading />

                            }

                        </div>
                    </div>

                </div>

            <Footer />

        </div>
    )

}

export default Categorie