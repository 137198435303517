import { API_ROOT, API_SECRET } from '../constants/general';

class Reservations {

    static mesReservations = (owner, option) => {

        let data = fetch(API_ROOT + "reservations/mesReservations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                owner: owner,
                option: option
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static reservation = (id) => {

        let data = fetch(API_ROOT + "reservations/reservation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)
            
            return data

        })

        return data

    }

    static uploadPhoto = (file, contrat_id, file_name) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        formdata.append('contrat_id', contrat_id)
        formdata.append('file_name', file_name)
        
        let data = fetch(API_ROOT + "reservations/photoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data

    }

}

export default Reservations