import React, { useEffect, useState } from 'react';

import { useParams, useNavigate, Link } from 'react-router-dom';

import { Header, Footer } from '../components/imports'

import { APP_NAME, MAPS_API_KEY, PHOTOS_ROOT } from '../constants/general'

import Annonces from '../class/Annonces'
import Func from '../class/Func';
import { Conditions, Equipements, Marques, VehiculCats } from '../components/generalImports';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';

function addOneDay(date = new Date()) {date.setDate(date.getDate() + 1);return date;}

function AnnonceSingle() {

    const { id } = useParams()

    const navigate = useNavigate()

    const [loader, setLodaer] = useState(false)

    const [error, setError] = useState('')

    const [article, setArticle] = useState(false)

    const [photos, setPhotos] = useState(false)

    const [startDate, setStartDate] = useState(new Date().toISOString().split("T")[0])
    const [endDate, setEndDate] = useState(false)

    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: addOneDay(),
        key: 'selection',
    })
    const [openDate, setOpenDate] = useState(false)
    
    const getArticle = async () => {

        let results = await Annonces.annoncesById(id)
        console.log(results.data)
        if(results.success){

            setArticle(results.data)

            setPhotos(results.photos)

            document.title = results.data.titre + " - " + APP_NAME

        }else{

            navigate('/404')

        }

    }

    const handleOpenDate = () => {
        setOpenDate((prev) => !prev)
    }

    const handleSelectDates = (ranges) => {
        setDate(ranges.selection)
        
        if(moment(ranges.selection.startDate).format() !== moment(ranges.selection.endDate).format()){
            setOpenDate((prev) => !prev)
        }
    }

    const reserver = async (e) => {

        e.preventDefault()

        setError('')

        setLodaer(true)

        let startDate = moment(date.startDate).format('YYYY-MM-DD')
        let endDate = moment(date.endDate).format('YYYY-MM-DD')

        let results = await Annonces.verifierDate(id, startDate, endDate)
        console.log("results=======")
        console.log(results)
        if(results.success){
            
            navigate('/reserver/' + id + '?start=' + startDate + '&end=' + endDate)

        }else{

            setError(results.message)

        }

        setLodaer(false)

    }

    useEffect(() => {

        getArticle()

        window.scrollTo(0,0)
        
    }, [])


    return (
        <div>

            <Header />

            <div className="single content">

                <div className="cc">
                    <div className="c-infos">
                        <h1 className="mb10">{ article.titre}</h1>
                        <div className="flex">

                            <div className='flex jc-start ai-center'>

                                {

                                article.nbr_note > 0

                                &&
                                
                                <div className="rating mr20">
                                    <span className='icon ri-star-fill mr5'></span> 
                                    <span className='p mr5'>{ article.note }</span>
                                    <span>({ article.nbr_note })</span>
                                </div>

                                }


                                <div className='place'>
                                    <span className='icon ri-map'></span>
                                    <span>{ article.adresse }</span>
                                </div>
                            </div>

                            <span className='price'>{ article.prix } DA<span className="p"> / j</span></span>
                        </div>

                    </div>

                    <div className="images mt30">
                        <div className="grid4">

                            {

                                photos

                                &&

                                photos.map(photo => <div className="img" key={ photo.idI }>
                                    <img src={ PHOTOS_ROOT + 'full/' + photo.name + '.jpg' } alt={ article.title } key={ photo.idI } />
                                </div>
                                
                                )

                            }

                        </div>
                    </div>

                    <div className="cont flex pos-r ai-start">

                        <div className="col-6">

                            <h2 className='mb30 fw5'>Caractéristiques du véhicule</h2>

                            <div className="grid2 car">
                                <div>
                                    <span className="icon ri-bookmark-line"></span>
                                    <span>{article.categorie !== undefined && VehiculCats.find((x) => x.value == parseInt(article.categorie))["label"]}</span>
                                </div>

                                <div>
                                    <span className="icon ri-car-line"></span>
                                    <span>{article.marque !== undefined && Marques.find((x) => x.value == parseInt(article.marque))["label"]}</span>
                                </div>

                                <div>
                                    <span className="icon ri-user-4"></span>
                                    <span>{article.nbr_places} places</span>
                                </div>

                                <div>
                                    <span className="icon ri-gas-station"></span>
                                    <span>{article.carburant}</span>
                                </div>

                                <div>
                                    <span className="icon ri-swap-box-line"></span>
                                    <span>{article.transmission}</span>
                                </div>

                                <div>
                                    <span className="icon ri-calendar-line"></span>
                                    <span>{article.annee}</span>
                                </div>

                                <div>
                                    <span className="icon ri-route-line"></span>
                                    <span>{article.kilometres} kms</span>
                                </div>

                                <div>
                                    <span className="icon ri-suitcase-3"></span>
                                    <span>{article.coffre} L</span>
                                </div>

                            </div>

                            <h2 className='mb30 fw5 mt30'>Equipements</h2>

                            <div className="wrap mb20">
                            {/* {getEquipements()} */}
                                {article.equipements !== undefined && JSON.parse(article.equipements).map(element => <span className="tag bg5">{Equipements.find((x) => x.id == parseInt(element))["value"]}</span> )}
                               
                            </div>

                            <h2 className='mb30 fw5'>Description</h2>

                            <div className='paragraph'>
                                <p>{ article.description }</p>
                            </div>

                            <h2 className='mb30 fw5 mt30'>Conditions requises </h2>

                            <div className="paragraph mb30">
                                <p>{ article.conditions }</p>
                            </div>

                            <div className="wrap mb20">
                                {article.autres_conditions !== undefined && JSON.parse(article.autres_conditions).map(element => <span className="tag bg5"><span className='icon cl4 ri-checkbox-circle-fill'></span>{Conditions.find((x) => x.id == parseInt(element))["value"]}</span> )}
                            </div>

                            <h2 className='mb30 fw5 mt30'>Localisation du véhicule</h2>

                            <div className="grid2">

                                <div className="map">
                                    <iframe src={`https://www.google.com/maps/embed/v1/place?key=${MAPS_API_KEY}&q=${article.adresse}`} style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>

                                <div>
                                    <div className='flex jc-start fz13 mb20'>
                                        <span className='icon mr10 cl3 ri-map'></span>
                                        <span>{ article.adresse }</span>
                                    </div>
                                    <div>
                                        { article.parking == "0" ?
                                            <p className='cl2'>Ce vehicule n'a pas une place de parking réservée. Il faut chercher une place au retour.</p>
                                            :
                                            <p className='cl2'>Ce vehicule a une place de parking réservée. Vous n'avez pas besoin de chercher une place au retour.</p>
                                        }
                                    </div>
                                </div>

                            </div>

                            {/* <h2 className='mb30 fw5 mt30'>Avis des clients</h2>

                            <div className="comments">
                                <div className='comment bg5'>
                                    <div className='av'>
                                        <img src="assets/img/service/small/avatar.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <div className="flex">
                                            <span className='n'>Messaoudi Khaled</span>
                                            <span className='d'>il ya une semaine</span>
                                        </div>
                                        <div className="rating">
                                            <span className='icon ri-star-fill'></span>
                                            <span className='p'>4.6</span>
                                            <span>(45)</span>
                                        </div>
                                        <p>Voiture conforme à l'annonce. Propriétaire disponible. Merci pour la location</p>
                                    </div>
                                </div>

                                <div className='comment bg5'>
                                    <div className='av'>
                                        <img src="assets/img/service/small/avatar.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <div className="flex">
                                            <span className='n'>Messaoudi Khaled</span>
                                            <span className='d'>il ya une semaine</span>
                                        </div>
                                        <div className="rating">
                                            <span className='icon ri-star-fill'></span>
                                            <span className='p'>4.6</span>
                                            <span>(45)</span>
                                        </div>
                                        <p>Voiture conforme à l'annonce. Propriétaire disponible. Merci pour la location</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>

                        {

                            localStorage.getItem('idU') !== article.idU

                            &&

                            <div className="col-38 side">

                                <p>Annonce publiée par</p>

                                <div className='user_card jc-between mt20'>

                                    <div className='flex ai-center'>
                                        <img src={ Func.avatar(article.avatar) } alt='' className='img_50'/>
                                        <h3 className='mb0'>{ article.name }</h3>
                                    </div>

                                    <Link to={'/envoyer-message/' + article.idU} className="btn2 bg3 cl1 p10" rel="nofollow">Contacter</Link>

                                </div>

                                <p className='rem fz08 mb20'>Vous avez besoin d'autres informations sur ce véhicle ? contactez le propriétaire.</p>

                                <div className="book">

                                    <span className='price'>{ article.prix } DA<span className="p"> / j</span></span>

                                    {

                                        !loader

                                        ?

                                        <>
                                            {(error !== '') && <p className="error">{error}</p>}  

                                            <form className="mt20" onSubmit={ (e) => reserver(e) }>
                                                <span className='pos-r d-block'>
                                                    {openDate && <div className='calendar'>
                                                        <DateRangePicker
                                                            ranges={[date]}
                                                            onChange={handleSelectDates}
                                                            months={1}
                                                            minDate={new Date()}
                                                        />
                                                    </div>}
                                                </span>
                                                <div className="flex jc-start ai-center" onClick={handleOpenDate}>
                                                    <span className="icon cl6 ri-calendar"></span>
                                                    <div className='label ml20'>
                                                        <p className='cl6'>Récupérer le</p>
                                                        <span className='input fw5'>{moment(date.startDate).format('ddd Do MMM')}</span>
                                                    </div>
                                                </div>

                                                <div className="flex jc-start ai-center" onClick={handleOpenDate}>
                                                    <span className="icon cl6 ri-calendar"></span>
                                                    <div className='label ml20'>
                                                        <p className='cl6'>Déposer le</p>
                                                        <span className='input fw5'>{moment(date.endDate).format('ddd Do MMM')}</span> 
                                                    </div>
                                                </div>


                                                {/* <div className="flex jc-start ai-center">
                                                    <span className="icon cl6 ri-calendar"></span>
                                                    <input type="date" placeholder="Récupérer le" name='start' min={ new Date().toISOString().split("T")[0] } onChange={ (start) => setStartDate(start.target.value) } required={true}/>
                                                </div>

                                                <div className="flex jc-start ai-center">
                                                    <span className="icon cl6 ri-calendar"></span>
                                                    <input type="date" placeholder="Déposer le" name='end' min={ startDate } onChange={ (end) => setEndDate(end.target.value) } required={true}/>
                                                </div> */}

                                                <input type="submit" value="Réserver" className='btn bg3 cl1' />

                                                

                                            </form>

                                            
                                        </>

                                        :

                                        <div className='flex jc-center mt50 mb50'>
                                            <div className="loader"></div>
                                        </div>

                                    }

                                </div>
                            </div>

                        }

                    </div>
                </div>

            </div>

            <Footer />

        </div>
    )

}

export default AnnonceSingle