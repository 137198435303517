import React, { useState, useEffect, useContext, useRef } from 'react';

import { useNavigate, Link, useParams, useSearchParams } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { APP_NAME, PHOTOS_ROOT } from '../../constants/general'

import { Context } from '../../class/Context'

import User from '../../class/User'

import Func from '../../class/Func'

import Annonces from '../../class/Annonces'
import { Conditions, Equipements, Marques, NbrPlaces, Villes } from '../../components/generalImports';

function ModifierAnnonce() {

    let { id } = useParams()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [photosUrls, setPhotosUrls] = useState([])

    const [annonce, setAnnonce] = useState(false)
    const [photos, setPhotos] = useState(false)

    const [error, setError] = useState(false)

    const context = useContext(Context)

    const [article, setArticle] = useState(false)

    const [get_params] = useSearchParams()

    const created_variable = get_params.get("created")


    const [equipements, setEquipements] = useState([])
    const [autreConditions, setAutreConditions] = useState([])
    const [locationLat, setLocationLat] = useState()
    const [locationLng, setLocationLng] = useState()

    const adresseRef = useRef(null)

    const onchangeEquipements = (e) => {
        let equi = equipements;
        if(equi.includes(e.target.value)) {
            equi.splice(equi.indexOf(e.target.value), 1)
        }else {
            equi.push(e.target.value)
        }
        setEquipements(equi);
    }

    const onchangeAutreConditions = (e) => {
        let condi = autreConditions;
        if(condi.includes(e.target.value)) {
            condi.splice(condi.indexOf(e.target.value), 1)
        }else {
            condi.push(e.target.value)
        }
        setAutreConditions(condi);
    }

    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        
        setLocationLat(place.geometry.location.lat())
        setLocationLng(place.geometry.location.lng())
    }

    const getArticle = async () => {

        let results = await Annonces.annoncesById(id)
        
        setArticle(results.data)
        setLocationLat(results.data.geo_lat)
        setLocationLng(results.data.geo_long)
        setPhotos(results.photos)
        setEquipements(JSON.parse(results.data.equipements))
        setAutreConditions(JSON.parse(results.data.autres_conditions))

        document.title = results.data.titre + " - " + APP_NAME

    }

    const modifier = async (e) =>{

        e.preventDefault()
        
        let inputs = e.target

        let equi = []
        console.log("equipements=======")
        console.log(equipements)
        for (let index = 0; index < equipements.length; index++) {
            const element = equipements[index];

            equi.push({id: element} )
        }

        let cond = []
        
        for (let index = 0; index < autreConditions.length; index++) {
            const element = autreConditions[index];

            cond.push({id: element} )
        }
        
        const results = await Func.fetch(
            "annonces/modifier", 
            {
                annonce_id: article.idA,
                titre: inputs.titre.value,
                adresse: inputs.adresse.value,
                wilaya: inputs.wilaya.value,
                parking: inputs.parking.checked,
                categorie: inputs.categorie.value,
                prix: inputs.prix.value,
                description: inputs.description.value,
                conditions: inputs.conditions.value,
                autres_conditions: cond,
                equipements: equi,
                marque: inputs.marque.value,
                annee: inputs.annee.value,
                nbr_places: inputs.nbrPlaces.value,
                carburant: inputs.carburant.value,
                transmission: inputs.transmission.value,
                kilometres: inputs.kilometres.value,
                coffre: inputs.coffre.value,
                geo_lat: locationLat,
                geo_long: locationLng
            }
        )
            
        if(results.success){
    
            Func.setFlash(results.message)
    
        }else{
    
            setError(results.message)
    
        }

        window.scrollTo(0, 0)
    
    }

    const uploadPhotos = (e, setError, setPhotos, photos, setLoading, id) => {

        let files = e.target.files
    
        if(files.length <= 6){
    
            Array.from(files).forEach(photo => {
    
                upload(photo, setPhotos, photos, setError, setLoading, id)
    
            })
    
        }else{
    
            setError(Text.nombre_de_photos)
    
            e.target.files = null
    
        }
    
    }
    
    const upload = async (file, setPhotos, photos, setError, setLoading, id) => {
    
        if(file.size == 0 || file.name == null) setError("Impossible d'ajouter cette photo une erreur est servenu.")
    
        setLoading(true)
    
        let results = await Annonces.photoUpload(file, id)
    
        setLoading(false)
    
        if (results.success){
    
            if(photos == undefined){
                photos = []
            }
            
            let new_element = photos
            new_element.push(results.data)
    
            setPhotos([])
    
            setInterval(function(){
                setPhotos(new_element)
            }, 1000)
    
        }else{
    
            setError(results.message)
            
        }
    
    }
    
    const uploadPhotosInput = () => {
    
        document.querySelector('#uploadPhotosInput').click()
    
    }
    
    const supprimerPhoto = async (e, id) => {
    
        let t = e.target.parentNode
    
        t.style.opacity = 0.5
    
        let results = await Annonces.supprimerPhoto(id)
    
        if (results.success){
    
            t.style.display = 'none'
    
        }else{
    
            alert(results.message)
    
        }
    
    }

    useEffect(() => {

        Func.session(context, navigate)

        getArticle()

        document.title = "Modifier une annonce - " + APP_NAME

        Func.initMapScript().then(() => Func.initMapPlaces(adresseRef.current, onChangeAddress))

    }, [])

    return (

        <div>

            <Header />

            <div className="content dashboard">
                <div className="col-5 m0a bg5 p20 br20">

                    <div className='col-10 flex ai-center mb30'>
                        <h2>{created_variable ? "Ajouter des photos" : "Modifier"} l'annonce N° { id }</h2>

                        {!created_variable && <Link to={'/mon-espace/annonces'} className="btn bg3 cl1 p10"><span className="icon ri-car-line"></span> Mes annonces</Link>}
                    </div>

                    {created_variable && <span className='fw5 fz11'>{article.titre}</span>}

                    {(error !== false) && <p className="error">{error}</p>}  

                    {
                        article

                        ?

                        <form className="gla-form" method='POST' onSubmit={(e) => modifier(e)}>

                            {/* Photos de l'annonce -------------------------------------------------------- */}

                            { created_variable && <p className='succes'>Annonce publié avec succès, Veuillez ajouter des photos pour votre annonce pour qu'elle s'affiche aux utilisateurs</p>}
                            
                            <div className="bg7 br8 p40 mt20 ta-center mb20">

                                <div className="col-9 m0a">
                                    
                                { 

                                    loading

                                    ?

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                    :
                                    
                                    <span className="btn brc3 brc1solid cl1 bg3 ta-center mb10 col-3 textUp" onClick={ (e) => uploadPhotosInput()}>Ajouter des photos</span>

                                }  

                                    {/* <span className='d-block'>Ajoute des photos.</span> */}
                                            
                                    <input type="file" name='uploadPhotosInput' id='uploadPhotosInput' accept='image/*' multiple maxLength='6' onChange={ e => uploadPhotos(e, setError, setPhotos, photos, setLoading, id) } className="d-none" style={{display: 'none'}}/>

                                </div> 

                                { 

                                    photos

                                    &&

                                    <div className='grid4 jc-center m0a pt20'>

                                        {

                                            photos.map(image =>

                                            <div key={image.idI} style={{width: '150px', margin: '30px 10px 10px'}}>
                                                <img src={PHOTOS_ROOT + 'small/' + image.name + '.jpg'} style={{width: '150px',height: '150px', marginBottom: '10px', borderRadius: '10px', objectFit: 'cover'}} />
                                                <span className='btn cl1 fz07 p5 bg4' onClick={(e) => supprimerPhoto(e, image.idI)}>Supprimer</span>
                                            </div>

                                            )

                                        }

                                    </div>

                                }  


                                { photosUrls.map(image => <img src={image} style={{width: '100px',height: '100px', margin: '10px', borderRadius: '5px', objectFit: 'cover'}} />) }  
                                
                                {created_variable && 
                                    <div className='flex jc-end'>
                                        <Link to={'/mon-espace/annonces'} className="btn bg4 cl1 p10 mt30 d-in-block">Terminer</Link>
                                    </div>
                                }
                            </div>

                            {!created_variable && <div>

                                <div>
                                    <label>Titre de l'annonce <span className='cl4'>*</span></label>
                                    <input type="text" name="titre" placeholder="Titre ..." required={true} defaultValue={ article.titre } />
                                </div>

                                <div className='grid2'>

                                    <div>
                                        <label>Montant / Jour (DA) <span className='cl4'>*</span></label>
                                        <input type="number" name="prix" placeholder="Mon prix ..." required={true} defaultValue={ article.prix }/>
                                    </div>

                                </div>

                                <div className='mb20'>
                                    <label>Description <span className='cl4'>*</span></label>
                                    <textarea name='description' placeholder='Je décrit ma voiture ...' defaultValue={ article.description }></textarea>

                                    <p className='fz08' style={{marginTop: '-20px'}}>Ex : Véhicule en excellent état, de décembre 2020, Nettoyage très fréquent ...</p>
                                </div>

                                <span className='fz12 mb20 d-block fw5'>Emplacement du véhicule</span>
                                <div>
                                    <label>Wilaya <span className='cl4'>*</span></label>
                                    <select name="wilaya" required={true} defaultValue={article.wilaya}>
                                        <option disabled>Wilaya</option>
                                        { Villes.map(element =>
                                            <option value={element.value}>{element.label}</option>
                                        )}
                                    </select>
                                </div>

                                <div>
                                    <label>Adresse<span className='cl4'>*</span></label>
                                    <input ref={adresseRef} type="text" name="adresse" placeholder="Adresse ..." required={true} className="mb10" defaultValue={ article.adresse }/>
                                </div>

                                <div className='cgu_accept fz08'>
                                    <input type="checkbox" name='parking' className='col-1' defaultChecked={parseInt(article.parking)}/>
                                    <p>Mon véhicule à une place de parking ou un garage.</p>
                                </div>
                                
                                <span className='fz12 mb20 d-block fw5'>Caractéristiques du véhicule</span>

                                <div className='grid2'>

                                    <div>
                                        <label>Catégorie du véhicule <span className='cl4'>*</span></label>
                                        <select name="categorie" required={true} defaultValue={ article.categorie }>
                                            <option value="1">Citadine</option>
                                            <option value="2">Berline</option>
                                            <option value="3">Mini</option>
                                            <option value="4">SUV</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label>Marque du véhicule <span className='cl4'>*</span></label>
                                        <select name="marque" required={true} defaultValue={article.marque}>
                                            { Marques.map(element =>
                                                <option value={element.value}>{element.label}</option>
                                            )}
                                        </select>
                                    </div>

                                </div>

                                <div className='grid2'>

                                    <div>
                                        <label>Année du véhicule <span className='cl4'>*</span></label>
                                        <select name="annee" required={true} defaultValue={article.annee}>
                                        { Func.generateYears().map(element => 
                                                <option value={element.value}>{element.label}</option>
                                            )}
                                        </select>
                                    </div>

                                    <div>
                                        <label>Nombre de places <span className='cl4'>*</span></label>
                                        <select name="nbrPlaces" required={true} defaultValue={article.nbr_places}>
                                        { NbrPlaces.map(element =>
                                                <option value={element.value}>{element.label}</option>
                                            )}
                                        </select>
                                    </div>

                                </div>

                                <div className='grid2'>

                                    <div>
                                        <label>Type de carburant <span className='cl4'>*</span></label>
                                        <select name="carburant" required={true} defaultValue={article.carburant}>
                                            <option>Essence</option>
                                            <option>Diesel</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label>Transmission <span className='cl4'>*</span></label>
                                        <select name="transmission" required={true} defaultValue={article.transmission}>
                                            <option>Manuelle</option>
                                            <option>Automatique</option>
                                            <option>Semi-automatique</option>
                                        </select>
                                    </div>

                                </div>

                                <div className='grid2 mb20'>

                                    <div>
                                        <label>Kilomètres<span className='cl4'>*</span></label>
                                        <input type="number" name="kilometres" placeholder="120 000" required={true} defaultValue={article.kilometres}/>
                                    </div>

                                    <div>
                                        <label>Volume du coffre (Litre) <span className='cl4'>*</span></label>
                                        <input type="number" name="coffre" placeholder="220" required={true} defaultValue={article.coffre}/>
                                    </div>

                                </div>

                                <div className='mb20'>
                                    <label>Equipements du véhicule</label>
                                    
                                    <div className='grid2 cgu_accept mt10'>

                                        {Equipements.map(element => 
                                        
                                            <div>
                                                <input type="checkbox" name={element.value} value={element.id} onChange={onchangeEquipements} className='mr5 mb0' defaultChecked={article.equipements.includes(element.id) ? true : false}/>
                                                <p>{element.value}</p>
                                            </div>
                                        )}

                                    </div>

                                </div>

                                <div className='mt30 mb30'>
                                    <label>Conditions requises <span className='cl4'>*</span></label>
                                    <textarea name='conditions' placeholder='Conditions requises pour louer ma voiture ...' defaultValue={ article.conditions }></textarea>

                                    <p className='fz08' style={{marginTop: '-20px'}}>Ex : Merci de ne pas fumer dans la voiture et de la rendre dans l'état dans lequel vous l'avez trouvée !, Dans le cas contraire, je me verrai dans l’obligation d’appliquer des frais de nettoyage de 500 DA</p>
                                </div>

                                <div className='mb20'>
                                    <label>Autres conditions</label>
                                    
                                    <div className='grid2 cgu_accept mt10'>

                                        {Conditions.map(element => 
                                            <div>
                                                <input type="checkbox" name={element.value} value={element.id} onChange={onchangeAutreConditions} className='mr5 mb0' defaultChecked={article.autres_conditions.includes(element.id) ? true : false}/>
                                                <p>{element.value}</p>
                                            </div>
                                        )}

                                    </div>

                                </div>

                                <input type="submit" value='Modifier' className='btn bg3 cl1 brc3 brc1solid' />
                            </div>}
                        </form>
                        :

                        <div className='flex jc-center mt50 mb50'>
                            <div className="lds-dual-ring col"></div>
                        </div>

                    }

                </div>
            </div>

            <Footer />

        </div>

    )

}

export default ModifierAnnonce