const VehiculCats = [
    { label: 'Citadine', value: '1' },
    { label: 'Berline', value: '2' },
    { label: 'Mini', value: '3' },
    { label: 'SUV', value: '4' },
]

const Marques = [
    
    {
      value: "1",
      label: "Audi"
    },
    {
      value: "2",
      label: "BMW"
    },
    {
      value: "3",
      label: "Chevrolet"
    },
    {
      value: "4",
      label: "Citroën"
    },
    {
      value: "5",
      label: "Dacia"
    },
    {
      value: "6",
      label: "Renault"
    },
    {
      value: "7",
      label: "Skoda"
    },
  ]

const CarburantType = [
    { label: 'Essence', value: 'essence' },
    { label: 'Diesel', value: 'diesel' },
]

const Transmission = [
    { label: 'Manuelle', value: '1' },
    { label: 'Automatique', value: '2' },
    { label: 'Semi-automatique', value: '3' },
]

const NbrPlaces = [
    { label: '2 places', value: '2' },
    { label: '4 places', value: '4' },
    { label: '5 places', value: '5' },
    { label: '7 places', value: '7' },
]

const Equipements = [
  { id: '0', value: 'Climatisation' },
  { id: '1', value: 'Régulateur de vitesse' },
  { id: '2', value: 'GPS' },
  { id: '3', value: 'ABS' },
  { id: '4', value: 'Vitres électriques' },
  { id: '5', value: 'AppleCarPlay' },
  { id: '6', value: 'Android Auto' },
]

const Conditions = [
  { id: '0', value: 'Chauffeur 18 ans au minimum' },
  { id: '1', value: '2 ans de permis au minimum' },
  { id: '2', value: 'Usage seulement en Algerie' }
]

const Villes = [
  {value:"1",label:"Adrar"}, 
  {value:"2",label:"Chlef"}, 
  {value:"3",label:"Laghouat"}, 
  {value:"4",label:"Oum El Bouaghi"},
  {value:"5",label:"Batna"},
  {value:"6",label:"Béjaïa"},
  {value:"7",label:"Biskra"},
  {value:"8",label:"Bechar"},
  {value:"9",label:"Blida"},
  {value:"10",label:"Bouira"},
  {value:"11",label:"Tamanrasset"},
  {value:"12",label:"Tbessa"},
  {value:"13",label:"Tlemcen"},
  {value:"14",label:"Tiaret"},
  {value:"15",label:"Tizi Ouzou"},
  {value:"16",label:"Alger"},
  {value:"17",label:"Djelfa"},
  {value:"18",label:"Jijel"},
  {value:"19",label:"Se9tif"},
  {value:"20",label:"Saefda"},
  {value:"21",label:"Skikda"},
  {value:"22",label:"Sidi Bel Abbes"},
  {value:"23",label:"Annaba"},
  {value:"24",label:"Guelma"},
  {value:"25",label:"Constantine"},
  {value:"26",label:"Medea"},
  {value:"27",label:"Mostaganem"},
  {value:"28",label:"M'Sila"},
  {value:"29",label:"Mascara"},
  {value:"30",label:"Ouargla"},
  {value:"31",label:"Oran"},
  {value:"32",label:"El Bayadh"},
  {value:"33",label:"Illizi"},
  {value:"34",label:"Bordj Bou Arreridj"},
  {value:"35",label:"Boumerdes"},
  {value:"36",label:"El Tarf"},
  {value:"37",label:"Tindouf"},
  {value:"38",label:"Tissemsilt"},
  {value:"39",label:"El Oued"},
  {value:"40",label:"Khenchela"},
  {value:"41",label:"Souk Ahras"},
  {value:"42",label:"Tipaza"},
  {value:"43",label:"Mila"},
  {value:"44",label:"Ain Defla"},
  {value:"45",label:"Naama"},
  {value:"46",label:"Ain Temouchent"},
  {value:"47",label:"Ghardaefa"},
  {value:"48",label:"Relizane"},
  {value:"49",label:"El M'ghair"},
  {value:"50",label:"El Menia"},
  {value:"51",label:"Ouled Djellal"},
  {value:"52",label:"Bordj Baji Mokhtar"},
  {value:"53",label:"Béni Abbès"},
  {value:"54",label:"Timimoun"},
  {value:"55",label:"Touggourt"},
  {value:"56",label:"Djanet"},
  {value:"57",label:"In Salah"},
  {value:"58",label:"In Guezzam"}
  
]


export { VehiculCats, Marques, CarburantType, Transmission, NbrPlaces, Equipements, Conditions, Villes }