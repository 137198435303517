import React, { useState, useEffect, useContext, useRef } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Header, Footer, AnnonceCard, Loading } from '../components/imports'

import { WEBROOT, APP_NAME, MAPS_API_ROOT, MAPS_API_KEY } from '../constants/general'

import Annonces from '../class/Annonces';

import gla_slider from '../class/Slider.js'
import Func from '../class/Func';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { Villes } from '../components/generalImports.js';

function Accueil() {

    const [articles, setArticles] = useState(false)
    const [categories, setCategories] = useState(false)
    const [villes, setVilles] = useState(false)
    const searchRef = useRef(null)
    const [locationLat, setLocationLat] = useState(false)
    const [locationLng, setLocationLng] = useState(false)
    const [date, setDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })
    const [openDate, setOpenDate] = useState(false)

    const navigate = useNavigate()

    const getHome = async () => {

        let results = await Annonces.acceuil()
        
        setArticles(results.ajouts_recent)
        setCategories(results.categories)

        setTimeout(() => {
            
            new gla_slider('#categories', {
                childs: 4,
                items: '.category',
                speed: 7,
                margin: 15
            })

        }, 100)

    }

    //-------- Search
    const onChangeAddress = (autocomplete) => {

        const place = autocomplete.getPlace()

        setLocationLat(place.geometry.location.lat())
        setLocationLng(place.geometry.location.lng())

    }

    const handleOpenDate = () => {
        setOpenDate((prev) => !prev)
    }

    const handleSelectDates = (ranges) => {
        setDate(ranges.selection)

        if(moment(ranges.selection.startDate).format() !== moment(ranges.selection.endDate).format()){
            setOpenDate((prev) => !prev)
        }
    }

    const search = async() => {
        
        // const results = await Func.fetch("annonces/search", {geo_lat: locationLat, geo_long: locationLng, start_date: date.startDate, end_date: date.endDate}, false)
        // console.log(results)
        
        navigate('/search', {state:{searchVal: searchRef.current.value, dateRange: date}})
    }
     //-------------- //

    useEffect(() => {

        document.title = "Louer mon véhicule en Algérie - " + APP_NAME

        getHome()
        
        Func.initMapScript().then(() => Func.initMapPlaces(searchRef.current, onChangeAddress))

        new gla_slider('#gla_slider', {
            childs: 2,
            items: '.child',
            speed: 7,
            margin: 20
        })

    }, [])


    return (
        <div>

            <Header />

                <div>
                    <div className="bg1">
                        <div className="caroussel" id="caroussel" >
                        
                            <div className="masq flex col-10 jc-center">

                                <div className="col-48 flex ai-start jc-center flex-col p20 mb120">

                                    <h1 className="h1 fade anim_left fade_in mb30"><span className="cl3 fz2 fw7">La voiture que vous voulez </span><span className='cl4 d-block fz13'>pour partir ou vous voulez</span></h1>

                                    <p className="cl2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, illo quasi numquam consequuntur dignissimos assumenda rem id cumque fugiat corporis quia nemo, hic aliquid at culpa ad quaerat voluptas rerum!</p>

                                </div>

                                <div className="col-48 pos-r">
                                    
                                    <div className="slider" id="gla_slider">

                                        <div className='child'>
                                            <img src="image/slider/slider-img1.jpeg" alt=''/>
                                        </div>
                                        <div className='child'>
                                            <img src="image/slider/slider-img2.jpeg" alt=''/>
                                        </div>
                                        <div className='child'>
                                            <img src="image/slider/slider-img3.jpeg" alt=''/>
                                        </div>
                                        <div className='child'>
                                            <img src="image/slider/slider-img4.jpeg" alt=''/>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="srch p30 ml20">
                                <form action="search" method="GET" className="bg1 pl10 pr10 flex jc-between m0a shad col-10 br30">
                                    <input name='startDate' type="text" className='d-none' value={moment(date.startDate).format('YYYY-MM-DD')}/>
                                    <input name='endDate' type="text" className='d-none' value={moment(date.endDate).format('YYYY-MM-DD')}/>
                                    <input name='lat' type="text" className='d-none' value={locationLat}/>
                                    <input name='lng' type="text" className='d-none' value={locationLng}/>
                                    
                                    <div className="flex jc-start ai-center col-38">
                                        <span className="icon cl6 ri-map"></span>
                                        <label htmlFor="adresse">
                                            <p className='cl6'>Lieu de ramassage</p>
                                            <input ref={searchRef} name='adresse' id='adresse' type="text" placeholder="Adresse précise" required={true} />
                                        </label>
                                    </div>

                                    <div className="flex jc-start ai-center col-25">
                                        <span className="icon cl6 ri-calendar"></span>
                                        <div className='label' onClick={handleOpenDate}>
                                            <p className='cl6'>Récupérer le</p>
                                            <span className='input fw5'>{moment(date.startDate).format('ddd Do MMM')}</span>
                                        </div>
                                    </div>

                                    <div className="flex jc-start ai-center col-25">
                                        <span className="icon cl6 ri-calendar"></span>
                                        <div className='label' onClick={handleOpenDate}>
                                            <p className='cl6'>Déposer le</p>
                                            <span className='input fw5'>{moment(date.endDate).format('ddd Do MMM')}</span> 
                                        </div>
                                    </div>

                                    <button className="icon bg3 cl1 p20 br30 ri-search brc0"></button>
                                    
                                    {openDate && <div className='calendar'>
                                        <DateRangePicker
                                            ranges={[date]}
                                            onChange={handleSelectDates}
                                            months={2}
                                            minDate={new Date()}
                                        />
                                    </div>}
                                </form>

                            </div>
                            
                        </div>
                    </div>

                    <div className="c1 c">
                        
                        <h2 className="mb30 fw5">Rechercher par catégorie</h2>

                        <div className="slider pb20" id="categories">

                            {

                                categories

                                &&

                                    categories.map(categorie => 
                                        <Link to={ '/categorie/' + categorie.url } className='category cl2' key={ categorie.idC }>
                                            <img src={ WEBROOT + 'gla-adminer/uploads/article/small/' + categorie.image } alt={ categorie.name }/>
                                            <div className="cnt">
                                                <h3>{ categorie.name }</h3>
                                                <p>{ categorie.description }</p>
                                            </div>
                                        </Link>
                                    )


                            }

                        </div>

                    </div>

                    <div className="c2 bg5 p50 grid3">

                        <div className=''>
                            <img src="image/large/booking.png" alt=''/>
                            <span>Réservation facile et rapide</span>
                        </div>
                        <div className=''>
                            <img src="image/large/security.png" alt=''/>
                            <span>Assurance multirisque et assistance 24/7.</span>
                        </div>
                        <div className=''>
                            <img src="image/large/discount.png" alt=''/>
                            <span>Garantie du meilleur prix</span>
                        </div>

                    </div>

                    <div className="c pt50 pb50">
                        
                        <h2 className="mb30 fw5">Rechercher par ville</h2>

                        <div className="pb20 villes grid4">
                            {Villes.slice(0, 8).map(element =>
                                <Link to={'/ville/' + element.value} className='ville brc5'>
                                    <img src={"image/villes/" +element.value +".jpeg"} alt=''/>
                                    <div className="cnt">
                                        <span className='t'>{element.label}</span>
                                    </div>
                                </Link>
                            )}
                        </div>

                    </div>

                    <div className="c3 c pt100 pb100 br50" style={{backgroundSize: 'cover', backgroundImage: 'url(image/large/back.jpeg)', backgroundRepeat: 'no-repeat',backgroundPosition: 'top'}}>
                        <div className="ta-center">
                            <div className="col-6 m0a">
                                <h2 className="fz3 mb20 cl1">Votre voiture est toujours au garrage? louer-la en quelques clics</h2>
                                <span className="fz2 mb30 d-block cl4 fw7">Tous les trajet sont assurés</span>
                                <p className="fw5 mb30 cl1 fz12">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis dolorum cumque similique vero accusamus. Pariatur, cupiditate labore! Ipsum sapiente vel, exercitationem at reiciendis harum? Beatae quae rem temporibus culpa neque?</p>
                                
                                <a href="" className="btn bg3 d-in-block cl1">Louer ma voiture</a>

                            </div>
                        </div>
                    </div>

                    <div className="c4 c pt100 pb100">

                        <h2 className="mb20 fw5">Trouvez la voiture qu'il vous faut </h2>
                        <p className='mb30 cl6'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perspiciatis, amet consectetur qui.</p>

                        <div className="articles grid4">
                            
                            {

                                articles

                                ?

                                articles.map(element => <AnnonceCard data={element} key={element.idA} />)

                                :

                                <Loading />

                            }

                        </div>
                    </div>

                </div>

            <Footer />
        </div>
    )

}

export default Accueil