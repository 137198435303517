import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBwAbV8wZ5uj1KjA9XEM4P6yGAgIPLIK4I",
  authDomain: "ekriny-2023.firebaseapp.com",
  projectId: "ekriny-2023",
  storageBucket: "ekriny-2023.appspot.com",
  messagingSenderId: "238838807220",
  appId: "1:238838807220:web:c817ecfc20764d24bb35e1"
}

const app = initializeApp(firebaseConfig)

const firebase_db = getFirestore(app)

export { firebase_db }