import React, { useState, useEffect, useContext, useRef } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import User from '../../class/User'

import Func from '../../class/Func'

import Annonces from '../../class/Annonces'
import { CarburantType, Conditions, Equipements, Marques, NbrPlaces, Transmission, Villes } from '../../components/generalImports';

function Publier() {

    const navigate = useNavigate();

    const [error, setError] = useState(false)
    const [equipements, setEquipements] = useState([])
    const [autreConditions, setAutreConditions] = useState([])
    const [locationLat, setLocationLat] = useState()
    const [locationLng, setLocationLng] = useState()

    const adresseRef = useRef(null)

    const context = useContext(Context)


    const onchangeEquipements = (e) => {
        let equi = equipements;
        equi.push({id: e.target.value})
        setEquipements(equi);
    }

    const onchangeAutreConditions = (e) => {
        let condi = autreConditions;
        condi.push({id: e.target.value})
        setAutreConditions(condi);
    }

    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        
        setLocationLat(place.geometry.location.lat())
        setLocationLng(place.geometry.location.lng())
    }

    const publier = async (e) =>{

        e.preventDefault()
        
        let inputs = e.target
        
        const results = await Annonces.publier(inputs, equipements, autreConditions, locationLat, locationLng)
    
        if(results.success){
    
            Func.setFlash(results.message)
    
            navigate('/mon-espace/modifier-annonce/' + results.id + '?created=true')
    
        }else{
    
            setError(results.message)
    
            window.scrollTo(0, 0)
    
        }
    
    }

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Publier une annonce - " + APP_NAME

        Func.initMapScript().then(() => Func.initMapPlaces(adresseRef.current, onChangeAddress))

    }, [])

    return (

        <div>

            <Header />

            <div className="content dashboard">
                <div className="col-5 m0a bg5 p20 br20">

                    <div className='col-10 flex ai-center mb30'>
                        <h2>Publier une annonce</h2>

                        <Link to={'/mon-espace/annonces'} className="btn bg3 cl1 p10"><span className='icon ri-car-line'></span> Mes annonces</Link>
                    </div>

                    {(error !== false) && <p className="error">{error}</p>}  

                    <form className="gla-form" method='POST' onSubmit={(e) => publier(e)}>

                        <div>
                            <label>Titre de l'annonce <span className='cl4'>*</span></label>
                            <input type="text" name="titre" placeholder="Titre ..." required={true} />
                        </div>

                        <div className='grid2'>

                            <div>
                                <label>Montant / Jour (DA) <span className='cl4'>*</span></label>
                                <input type="number" name="prix" placeholder="Mon prix ..." required={true} />
                            </div>

                        </div>

                        <div className='mb30'>
                            <label>Description <span className='cl4'>*</span></label>
                            <textarea name='description' placeholder='Je décrit ma voiture ...'></textarea>

                            <p className='fz08' style={{marginTop: '-20px'}}>Ex : Véhicule en excellent état, de décembre 2020, Nettoyage très fréquent ...</p>
                        </div>

                        <span className='fz12 mb20 d-block fw5'>Emplacement du véhicule</span>

                        <div>
                            <label>Wilaya <span className='cl4'>*</span></label>
                            <select name="wilaya" required={true}>
                                <option disabled>Wilaya</option>

                                { Villes.map(element =>
                                    <option value={element.value}>{element.label}</option>
                                )}
                            </select>
                        </div>

                        <div>
                            <label>Adresse<span className='cl4'>*</span></label>
                            <input type="text" ref={adresseRef} name="adresse" placeholder="Adresse ..." required={true} className="mb10"/>
                        </div>

                        <div className='cgu_accept fz08'>
                            <input type="checkbox" name='parking' className='col-1'/>
                            <p>Mon véhicule à une place de parking ou un garage.</p>
                        </div>

                        {/* Ajouté Par Hanane ===================================*/}

                        <span className='fz12 mb20 d-block fw5'>Caractéristiques du véhicule</span>

                        <div className='grid2'>

                            <div>
                                <label>Catégorie du véhicule <span className='cl4'>*</span></label>
                                <select name="categorie" required={true}>
                                    <option value="1">Citadine</option>
                                    <option value="2">Berline</option>
                                    <option value="3">Mini</option>
                                    <option value="4">SUV</option>
                                </select>
                            </div>

                            <div>
                                <label>Marque du véhicule <span className='cl4'>*</span></label>
                                <select name="marque" required={true}>
                                   { Marques.map(element =>
                                        <option value={element.value}>{element.label}</option>
                                    )}
                                </select>
                            </div>

                        </div>

                        <div className='grid2'>

                            <div>
                                <label>Année du véhicule <span className='cl4'>*</span></label>
                                <select name="annee" required={true}>
                                   { Func.generateYears().map(element => 
                                        <option value={element.value}>{element.label}</option>
                                    )}
                                </select>
                            </div>

                            <div>
                                <label>Nombre de places <span className='cl4'>*</span></label>
                                <select name="nbrPlaces" required={true}>
                                   { NbrPlaces.map(element =>
                                        <option value={element.value}>{element.label}</option>
                                    )}
                                </select>
                            </div>

                        </div>

                        <div className='grid2'>

                            <div>
                                <label>Type de carburant <span className='cl4'>*</span></label>
                                <select name="carburant" required={true}>
                                    <option>Essence</option>
                                    <option>Diesel</option>
                                </select>
                            </div>

                            <div>
                                <label>Transmission <span className='cl4'>*</span></label>
                                <select name="transmission" required={true}>
                                    <option>Manuelle</option>
                                    <option>Automatique</option>
                                    <option>Semi-automatique</option>
                                </select>
                            </div>

                        </div>

                        <div className='grid2 mb20'>

                            <div>
                                <label>Kilomètres<span className='cl4'>*</span></label>
                                <input type="number" name="kilometres" placeholder="120 000" required={true} />
                            </div>

                            <div>
                                <label>Volume du coffre (Litre) <span className='cl4'>*</span></label>
                                <input type="number" name="coffre" placeholder="220" required={true} />
                            </div>

                        </div>

                        {/* =================================== / =====================*/}


                        <div className='mb20'>
                            <label>Equipements du véhicule</label>
                            
                            <div className='grid2 cgu_accept mt10'>

                                {Equipements.map(element => 
                                    <div>
                                        <input type="checkbox" name={element.value} value={element.id} onChange={onchangeEquipements} className='mr5 mb0'/>
                                        <p>{element.value}</p>
                                    </div>
                                )}

                            </div>

                        </div>

                        <div className='mt30 mb30'>
                            <label>Conditions requises <span className='cl4'>*</span></label>
                            <textarea name='conditions' placeholder='Conditions requises pour louer ma voiture ...'></textarea>

                            <p className='fz08' style={{marginTop: '-20px'}}>Ex : Merci de ne pas fumer dans la voiture et de la rendre dans l'état dans lequel vous l'avez trouvé !, Dans le cas contraire, je me verrai dans l’obligation d’appliquer des frais de nettoyage de 500 DA</p>
                        </div>

                        <div className='mb20'>
                            <label>Autres conditions</label>
                            
                            <div className='grid2 cgu_accept mt10'>

                                {Conditions.map(element => 
                                    <div>
                                        <input type="checkbox" name={element.value} value={element.id} onChange={onchangeAutreConditions} className='mr5 mb0'/>
                                        <p>{element.value}</p>
                                    </div>
                                )}

                            </div>

                        </div>

                        <input type="submit" value='Publier & ajouter des photos' className='btn bg3 cl1 brc3 brc1solid' />

                    </form>

                </div>
            </div>

            <Footer />

        </div>

    )

}

export default Publier