import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { APP_NAME, PHOTOS_ROOT, WEBROOT } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import { Menu } from '../../components/espace-membre'

import Reservations from '../../class/Reservations'

import Moment from 'react-moment';

function Reservation() {

    const { id } = useParams()

    const navigate = useNavigate()

    const context = useContext(Context)

    const [reservation, setReservation]= useState([])

    const [client, setClient] = useState(false)
    const [proprietaire, setProprietaire] = useState(false)

    const [photos, setPhotos]= useState([])

    const [flash, setFlash]= useState()

    const getReservation = async () => {

        let results = await Reservations.reservation(id)

        if(results.success){
            
            console.log(results)

            setReservation(results.data)
            setPhotos(results.photos)

            setClient(results.client)

            setProprietaire(results.proprietaire)

        }else{

            navigate('/404')

        }

    }

    const acceptRefuserReservation = async (action) => {

        setReservation('')

        window.scrollTo(0,0)

        let results = await Func.fetch("reservations/" + action, {id})

        if(results.success){
            
            setFlash({"type": "succes", "message": results.message})

        }else{

            setFlash({"type": "error", "message": results.message})

        }

        getReservation()

    }

    useEffect(() => {

        Func.session(context, navigate)

        getReservation()

        document.title = "Réservation - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c">

                    <Menu data="mon-espace"/>

                    <div className="cont col-78 fz09">
                        
                        { flash && <p className={flash.type}>{ flash.message }</p> }

                        {

                            reservation

                            ?

                                <>

                                    <div className='grid2'>

                                        <div>

                                            <h2 className='mb20'>Annonce</h2>

                                            <p className='mb10'>Titre : <Link to={'/annonce/' + reservation.idA}>{ reservation.titre }</Link></p>
                                            <p>Montant : { reservation.prix } DA/ jour</p>
                                            { reservation.message !== '' && <p className='mt10'>Message du client : { reservation.message }</p>}

                                        </div>

                                        {

                                        localStorage.getItem('idU') !== reservation.idU

                                        ?

                                            <div>

                                                <h2 className='mb10'>Client</h2>

                                                <div className='user_card' style={{broder: 'none', boxShadow: 'none', padding: 0}}>

                                                    <img src={ Func.avatar(reservation.avatar) } />
                                                    <h2 className='mb0'>{ reservation.name }</h2>

                                                </div>

                                            </div>

                                        :

                                            <div>

                                                <h2 className='mb10'>Propriétaire</h2>

                                                <div className='user_card' style={{broder: 'none', boxShadow: 'none', padding: 0}}>

                                                    <img src={ Func.avatar(reservation.p_avatar) } />
                                                    <h2 className='mb0'>{ reservation.p_name }</h2>

                                                </div>

                                            </div>

                                        }

                                    </div>

                                    <div className='grid5'>

                                        {

                                            photos

                                            &&

                                            photos.map(photo => <img src={PHOTOS_ROOT + 'full/' + photo.name + '.jpg'} key={ photo.idI } className="col-10 mt20 img_thumb"/>)

                                        }

                                    </div>
                                    
                                    <h2 className='mb20 mt20'>Reservation</h2>

                                    <div className='grid2'>
                                        <p className='mb10'>Date : Du <strong><Moment date={ reservation.start_date } format='DD/MM/YYYY'></Moment></strong> au <strong><Moment date={ reservation.end_date } format='DD/MM/YYYY'></Moment></strong></p>
                                        <p className='mb10'>Nombre de jours : <strong>{ reservation.nbr_days }</strong></p>
                                        <p className='mb10'>Date de réservation : <strong><Moment date={ reservation.reservation_date } format='DD/MM/YYYY à HH:mm'></Moment></strong></p>
                                        {localStorage.getItem('idU') !== reservation.idU && <p>Commission Ekriny : <strong>{ reservation.price * 0.05 } DA</strong></p>}
                                        <p>Montant total: <strong>{ reservation.price } DA</strong></p>
                                        {localStorage.getItem('idU') !== reservation.idU && <p>Montant à recevoir : <strong>{ reservation.price - reservation.price * 0.05 } DA</strong></p>}
                                    </div>



                                    <div className='mt50'>

                                    {
                                    // Mode Pro ---------------------------------------------------------------------------------
                                    // Mode Pro ---------------------------------------------------------------------------------
                                    // Mode Pro ---------------------------------------------------------------------------------
                                    // Mode Pro ---------------------------------------------------------------------------------
                                    // Mode Pro ---------------------------------------------------------------------------------
                                    }

                                    {

                                        // Mode Pro - En attente ---------------------------------

                                        localStorage.getItem('idU') !== reservation.idU && reservation.reservation_stt == 0

                                        &&

                                        <>

                                            <button className='btn bg3 cl1 mr10 brc0' onClick={ () => acceptRefuserReservation('accepter')}>Accepter</button>
                                            <button className='btn bg8 cl1 brc0' onClick={ () => acceptRefuserReservation('refuser')}>Refuser</button>

                                        </>

                                    }

                                    {

                                        // Mode Pro - Acceptée ---------------------------------

                                        localStorage.getItem('idU') !== reservation.idU && reservation.reservation_stt == 1

                                        &&

                                        <div>

                                            <p className='mb40 fw6 stt_1_3 bg5 p20 br10'><span className='icon ri-checkbox-circle-fill'></span> J'ai accepté cette réservation</p>

                                            <p className='cl3'><span className='icon ri-shield-check-fill'></span> En attente du paiement de <strong>{ reservation.name }</strong></p>

                                        </div>

                                    }

                                    {

                                        // Mode Pro - Refusée ---------------------------------

                                        localStorage.getItem('idU') !== reservation.idU && reservation.reservation_stt == 2

                                        &&

                                        <>

                                            <p className='stt_2 fw6'>J'ai refusé cette réservation</p>
                                            
                                        </>

                                    }

                                    {

                                        // Mode Pro - Refusée ---------------------------------

                                        localStorage.getItem('idU') !== reservation.idU && reservation.reservation_stt == 3

                                        &&

                                        <>

                                            <p className='mb20 stt_1_3 bg5 p20 br10'><span className='icon ri-checkbox-circle-fill'></span> Paiement effectué par <strong>{ reservation.name }</strong>, vous pouvez le contacter pour remplir le contrat</p>
                                            
                                            {

                                                client

                                                &&

                                                <div className='p10 fw5 mb40'>

                                                    <p className='mb10'>Tele : { client.tele }</p>
                                                    <p className='mb10'>Email : { client.email }</p>
                                                    <p>Adresse : { client.adresse }</p>

                                                </div>

                                            }

                                            <Link to={'/mon-espace/contrat/' + reservation.idR} className="btn bg3 cl1">{ reservation.contrat_stt == 0 ? 'Remplir le contrat' : 'Modifier le contrat'}</Link>
                                            
                                        </>

                                    }






                                    {
                                    // Mode Client ---------------------------------------------------------------------------------
                                    // Mode Client ---------------------------------------------------------------------------------
                                    // Mode Client ---------------------------------------------------------------------------------
                                    // Mode Client ---------------------------------------------------------------------------------
                                    // Mode Client ---------------------------------------------------------------------------------
                                    }

                                    {

                                        // En attente ---------------------------------

                                        localStorage.getItem('idU') == reservation.idU && reservation.reservation_stt == 0

                                        &&

                                        <>

                                            <p className='mb40 stt_1_3 bg5 p20 br10'>Réservation envoyée, En attente de la validation par <strong>{ reservation.p_name }</strong></p>
                                            
                                        </>

                                    }

                                    {

                                        // Acceptée ---------------------------------

                                        localStorage.getItem('idU') == reservation.idU && reservation.reservation_stt == 1

                                        &&

                                        <>

                                            <p className='mb20 stt_1_3 bg5 p20 br10'>Réservation accpetée par <strong>{ reservation.p_name }</strong>, vous pouvez payer pour continuer.</p>
                                            
                                            <p className='fz09 mb20'>* Vous avez 24h pour payer cette réservation, sinon elle sera annulée automatiquement.</p>

                                            <button className='btn bg3 cl1 mr10 brc0' onClick={ () => acceptRefuserReservation('payer')}><span className='icon ri-bank-card-fill'></span> Payer la réservation <strong>({ reservation.price } DA)</strong></button>

                                        </>

                                    }

                                    {

                                        // Refusée ---------------------------------

                                        localStorage.getItem('idU') == reservation.idU && reservation.reservation_stt == 2

                                        &&

                                        <>

                                            <p className='mb20 stt_1_3 bg5 p20 br10'>Réservation refusée par <strong>{ reservation.p_name }</strong>.</p>
                                            
                                        </>

                                    }

                                    {

                                        // Payée ---------------------------------

                                        localStorage.getItem('idU') == reservation.idU && reservation.reservation_stt == 3

                                        &&

                                        <>

                                            <p className='mb0 fw6 stt_1_3 p10'><span className='icon ri-checkbox-circle-fill'></span> Vous avez payé cette réservation</p>

                                            <p className='mb20 cl3 bg5 p20 br10'>Vous avez payé cette réservation, Vous avez les informations du propriétaire vous pouvez l'appeler pour remplir et signer le contrat pour continuer.</p>
                                            
                                            {

                                                proprietaire

                                                &&

                                                <div className='p10 fw5'>

                                                    <p className='mb10'>Tele : { proprietaire.tele }</p>
                                                    <p className='mb10'>Email : { proprietaire.email }</p>
                                                    <p>Adresse : { proprietaire.adresse }</p>

                                                </div>

                                            }

                                        </>

                                    }

                                    </div>

                                </>

                            :

                            <div className='flex jc-center mt50 mb50'>
                                <div className="loader"></div>
                            </div>

                        }

                    </div>

                </div>
            </div>

            <Footer/>

        </div>

    )

}

export default Reservation