import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useSearchParams, useLocation } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import { Menu } from '../../components/espace-membre'

import Reservations from '../../class/Reservations'
import Moment from 'react-moment';

function MonEspace() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const location = useLocation()

    const [reservations, setReservations] = useState([])

    const [get_params] = useSearchParams();

    const getReservations = async () => {

        setReservations([])

        let stt = 'recu'

        if(get_params.get('type') !== null) stt = get_params.get('type')

        let proMode = localStorage.getItem('proMode') == 'true' ? 1 : 2;

        let results = await Reservations.mesReservations(proMode, {type: stt})

        if(results.data){
            
            setReservations(results.data)

        }else{

            setReservations(results.message)

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        getReservations()

        document.title = "Mes réservations - " + APP_NAME

    }, [get_params])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c">

                    <Menu data="mon-espace"/>

                    <div className="cont col-78">

                        <ul className="nav">
                            <li className={ location.search == '' ? 'active' : undefined}><Link to={'/mon-espace'} className='cl2 fw5'>Toutes</Link></li>
                            <li className={ location.search == '?type=attente' ? 'active' : undefined}><Link to={'/mon-espace?type=attente'} className='cl2 fw5'>En attente</Link></li>
                            <li className={ location.search == '?type=acceptees' ? 'active' : undefined}><Link to={'/mon-espace?type=acceptees'} className='cl2 fw5'>Acceptées</Link></li>
                            <li className={ location.search == '?type=payees' ? 'active' : undefined}><Link to={'/mon-espace?type=payees'} hash={'encours'} className='cl2 fw5'>Payées</Link></li>
                            <li className={ location.search == '?type=encours' ? 'active' : undefined}><Link to={'/mon-espace?type=encours'} hash={'encours'} className='cl2 fw5'>En cours</Link></li>
                            <li className={ location.search == '?type=finalisees' ? 'active' : undefined}><Link to={'/mon-espace?type=finalisees'} className='cl2 fw5'>Finalisées</Link></li>
                            <li className={ location.search == '?type=autre' ? 'active' : undefined}><Link to={'/mon-espace?type=autre'} className='cl2 fw5'>Autre</Link></li>
                        </ul>

                        {

                            reservations.length > 0

                            ?

                                typeof reservations == 'string'

                                ?

                                <p className='p20 mt20'>{ reservations }</p>

                                :

                                <>

                                    <div className='reservation grid5 fz09 br15 mt20 bg6 p20'>
                                        <strong>Réservation</strong>
                                        <strong>Annonce</strong>
                                        <strong>Montant</strong>
                                        <strong>Durée</strong>
                                        <strong>Statut</strong>
                                    </div>

                                    { reservations.map(reservation => 
                                    
                                    <Link to={'/mon-espace/reservation/' + reservation.idR} className='reservation grid5 fz09 br15 mt5 bg5 p20 hover-bg7 cl2' key={ reservation.idR }>
                                        
                                        <span className='fw5'>Reservation : { reservation.idR }</span>
                                        
                                        <span className='cl2'>{ reservation.titre }</span>
                                        
                                        <p>{ reservation.price } DA</p>
                                        
                                        <p><strong>{ reservation.nbr_days } jours</strong> à partir du <Moment date={ reservation.start_date } format='DD/MM/YYYY'></Moment></p>
                                        
                                        <p>
                                        { reservation.reservation_stt == 0 && <p className='cl2'>En attente</p> }
                                        { reservation.reservation_stt == 1 && <p className='stt_1_3'>Acceptée</p> }
                                        { reservation.reservation_stt == 2 && <p className='stt_2'>Refusée</p> }
                                        { reservation.reservation_stt == 3 && <p className='cl3'>Payée</p> }
                                        { reservation.reservation_stt == 5 && <p className='stt_1_3'>En cours</p> }
                                        { reservation.reservation_stt == 4 && <p className='stt_4'>Finalisée</p> }
                                        { reservation.reservation_stt == 6 && <p className='cl2'>Annulée</p> }
                                        
                                        </p>
                                    </Link>
                                    
                                    )}
                                    
                                </>

                            :

                            <div className='flex jc-center mt50 mb50'>
                                <div className="loader"></div>
                            </div>

                        }

                    </div>

                </div>
            </div>

            <Footer/>

        </div>

    )

}

export default MonEspace