import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Header, Footer, AnnonceCard, Loading } from '../components/imports'

import { WEBROOT, APP_NAME } from '../constants/general'

import { Villes } from '../components/generalImports';

import Annonces from '../class/Annonces';

import Func from '../class/Func';

function Ville() {

    const { id } = useParams()
    
    const navigate = useNavigate()

    const [annonces, setAnnonces] = useState(false)

    const [villeName, setVilleName] = useState('')
    
    const getData = async () => {

        const results = await Func.fetch("annonces/byVille", {id}, false)
        
        console.log(results)

        if(results.success){
            
            setAnnonces(results.annonces)

        }else{

            setAnnonces(results.message)

        }

    }

    useEffect(() => {

        setVilleName(Villes.find((x) => x.value == parseInt(6))["label"])

        getData()

    }, [])


    return (
        <div>

            <Header />

                <div className='content'>

                    <div className='c flex jc-start ai-center'>

                        <img src={ '/image/villes/' + id + '.jpeg' } alt={ villeName } className="col-1 br15 mr20"/>
                        <div className="cnt">
                            <h3>{ villeName }</h3>
                        </div>

                    </div>

                    <div className="c4 c pt40 pb40">

                        <div className="articles grid4">
                            
                            {

                                annonces

                                ?

                                    typeof annonces == 'object'

                                    ?

                                    annonces.map(element => <AnnonceCard data={element} key={element.idA} />)

                                    :

                                    <p>{annonces}</p>

                                :

                                <Loading />

                            }

                        </div>
                    </div>

                </div>

            <Footer />

        </div>
    )

}

export default Ville