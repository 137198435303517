import { API_ROOT, API_SECRET } from '../constants/general';

class Annonces {

    static publier = (inputs, equipements, autreConditions, locationLat, locationLng) => {
        
        let data = fetch(API_ROOT + "annonces/publier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                titre: inputs.titre.value,
                adresse: inputs.adresse.value,
                wilaya: inputs.wilaya.value,
                parking: inputs.parking.checked,
                categorie: inputs.categorie.value,
                prix: inputs.prix.value,
                description: inputs.description.value,
                conditions: inputs.conditions.value,
                autres_conditions: autreConditions,
                equipements: equipements,
                marque: inputs.marque.value,
                annee: inputs.annee.value,
                nbr_places: inputs.nbrPlaces.value,
                carburant: inputs.carburant.value,
                transmission: inputs.transmission.value,
                kilometres: inputs.kilometres.value,
                coffre: inputs.coffre.value,
                geo_lat: locationLat,
                geo_long: locationLng

            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static mesAnnonces = () => {

        let data = fetch(API_ROOT + "annonces/mesAnnonces", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static byCategorie = (url) => {

        let data = fetch(API_ROOT + "annonces/byCategorie", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                categorie_url: url
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static annoncesById = (id) => {

        let data = fetch(API_ROOT + "annonces/annonceById", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static acceuil = () => {

        let data = fetch(API_ROOT + "annonces/acceuil", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static photoUpload = (file, id = 0) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        formdata.append('id', id)
        
        let data = fetch(API_ROOT + "annonces/photoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data
    }

    static getPhotosPublier = () => {

        let data = fetch(API_ROOT + "annonces/getPhotosPublier", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            
            return data

        })

        return data

    }

    static supprimerPhoto = (id) => {

        let data = fetch(API_ROOT + "annonces/supprimerPhoto", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)
            
            return data

        })

        return data

    }

    static verifierDate = (id, startDate, endDate) => {

        let data = fetch(API_ROOT + "annonces/verifierDate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id: id,
                startDate: startDate,
                endDate: endDate
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static reserver = (id, startDate, endDate, input) => {

        let data = fetch(API_ROOT + "annonces/reserver", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id,
                startDate: startDate,
                endDate: endDate,
                message: input.message.value
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

}

export default Annonces