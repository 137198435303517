import React, { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Header, Footer } from '../components/imports'

import { APP_NAME } from '../constants/general'
import { Context } from '../class/Context';

function LouerMaVoiture() {
    const context = useContext(Context)

    useEffect(() => {

        document.title = "Louer ma voiture - " + APP_NAME

    }, [])


    return (
        <div>

            <Header />

            <div className="rent-car">

                <div className="bg5 pb100">
                    <div className="c ta-center">

                        <div className=" col-6 flex ai-center jc-center flex-col pt50 pb50 m0a">

                            <h1 className="h1 fade anim_left fade_in mb30"><span className="cl2 fz2 fw7">Partagez votre voiture</span><span className='cl3 d-block fz13'>et Gagnez de l'argent</span></h1>

                            <p className="cl2 mb30">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, illo quasi numquam consequuntur dignissimos assumenda rem id cumque fugiat corporis quia nemo, hic aliquid at culpa ad quaerat voluptas rerum!</p>

                            <Link to={context.session ? "/mon-espace/publier" : "/connexion"} className="btn2 brc4 brc2solid cl1 d-in-block bg4">Commencer</Link>

                        </div>

                    </div>
                </div>

                <div className="b1">

                    <img src="image/large/rent-my-car.png" alt='' />

                </div>

                <div className="grid3 c mt30 mb30">
                    <div className='bg5 min-box'>

                        <span>Tous les trajets sont assurés</span>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>

                    </div>
                    <div className='bg5 min-box'>

                        <span>Un contrat pour chaque location</span>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>

                    </div>
                    <div className='bg5 min-box'>

                        <span>Choisissez vos dates et vos prix</span>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>

                    </div>
                </div>

                <div className="br20 c b2 flex ai-center pt80 pb80">

                    <div className="col-5">
                        <img src="image/large/how-it-works.png" alt='comment ça marche' />
                    </div>

                    <div className='col-48 steps'>

                        <h2 className='mb60 fw5 fz3'>Comment ça marche ?</h2>

                        <div className="flex step">
                            <span className='num bg6'>1</span>
                            <div>
                                <span>Inscrivez votre voiture gratuitement</span>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste possimus fugit reprehenderit.</p>
                            </div>
                        </div>

                        <div className="flex step">
                            <span className='num bg2'>2</span>
                            <div>
                                <span>Fixez votre prix et vos conditions</span>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste possimus fugit reprehenderit.</p>
                            </div>
                        </div>

                        <div className="flex step">
                            <span className='num bg3'>3</span>
                            <div>
                                <span>Louez votre voiture et accuillez vos clients</span>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste possimus fugit reprehenderit.</p>
                            </div>
                        </div>

                        <div className="flex step">
                            <span className='num bg4'>4</span>
                            <div>
                                <span>Gagnez de l’argent et recevez vos revenus</span>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste possimus fugit reprehenderit.</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="c3 c pt100 pb100 br50 mb40" style={{ backgroundSize: 'cover', backgroundImage: 'url(image/large/back3.jpeg)', backgroundRepeat: 'no-repeat',backgroundPosition: 'center' }}>
                    <div className="ta-center">
                        <div className="col-6 m0a">
                            <h2 className="fz3 mb20 cl1">N'hésitez plus! Louez en toute assurance</h2>
                            <span className="fz2 mb30 d-block cl4 fw7">Inscrivez votre voiture dès maintenant</span>
                            <p className="fw5 mb30 cl1 fz12">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis dolorum cumque similique vero accusamus. Pariatur, cupiditate labore! Ipsum sapiente vel, exercitationem at reiciendis harum? Beatae quae rem temporibus culpa neque?</p>

                            <Link to={context.session ? "/mon-espace/publier" : "/connexion"} className="btn bg3 d-in-block cl1">Commencer</Link>

                        </div>
                    </div>
                </div>


            </div>

            <Footer />

        </div>
    )

}

export default LouerMaVoiture