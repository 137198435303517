import { API_ROOT, API_SECRET } from '../constants/general';

class User {

    static register = (input) => {

        let data = fetch(API_ROOT + "register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                name: input.name.value,
                email: input.email.value,
                password: input.pass.value,
            })
               
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data

    }

    static login = (input) => {
        
        let data = fetch(API_ROOT + "login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                api_secret: API_SECRET,
                login: input.email.value,
                password: input.pass.value,
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            console.log(data)
            return data

        })

        return data
    }

    static session = () => {
        let data = fetch(API_ROOT + "session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static connected = async (setSession, setMessages) => {

        if (localStorage.getItem('idU') !== undefined && localStorage.getItem('app_token') !== undefined) {

            const results = await this.session()

            if (!results.success) {

                localStorage.removeItem('idU')
                localStorage.removeItem('app_token')
                localStorage.removeItem('name')
                localStorage.removeItem('email')
                localStorage.removeItem('avatar')

                setSession(false)

            } else {
                
                setSession(true)

                setMessages(results.messages)

            }

            return results.success

        } else {

            return false
        }
    }

    static photoUpload = (file) => {

        var formdata = new FormData()
        formdata.append('image', file)
        formdata.append('id_user', localStorage.getItem('idU'))
        formdata.append('app_token', localStorage.getItem('app_token'))
        
        let data = fetch(API_ROOT + "users/photoUpload", {
            method: "POST",
            body: formdata
        }).then(response => response.json())
        .then(data => {

            return data

        })

        return data
    }

    static informations = () => {
        let data = fetch(API_ROOT + "users/informations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token')
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static modifierInformations = (input) => {
        let data = fetch(API_ROOT + "users/modifierInformations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                name: input.name.value,
                email: input.email.value,
                tele: input.tele.value,
                adresse: input.adresse.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    static modifierMotdepasse = (input) => {
        let data = fetch(API_ROOT + "users/modifierMotdepasse", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                old_password: input.old_password.value,
                password: input.password.value
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            return data

        })

        return data
    }

    // Messages ----------------

    static envoyerMessage = (id) => {
        let data = fetch(API_ROOT + "discussions/createDiscussion", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                api_secret: API_SECRET,
                id_user: localStorage.getItem('idU'),
                app_token: localStorage.getItem('app_token'),
                id: id
            })
        }).then(function (response) {
            return response.json()
        }).then(function (data) {

            console.log(data)

            return data

        })

        return data
    }

}


export default User