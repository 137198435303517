import React, { useEffect, useState } from 'react';

import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';

import { Header, Footer } from '../components/imports'

import { APP_NAME, PHOTOS_ROOT } from '../constants/general'

import Annonces from '../class/Annonces'
import Moment from 'react-moment';

function Reserver() {

    const { id } = useParams()

    const [ date ] = useSearchParams()

    const navigate = useNavigate()

    const [error, setError] = useState('')
    const [infos, setinfos] = useState('')
    const [onReserve, setOnReserve] = useState(false)

    const [article, setArticle] = useState(false)

    const [photos, setPhotos] = useState(false)
    
    const getArticle = async () => {

        let results = await Annonces.annoncesById(id)

        setArticle(results.data)

        setPhotos(results.photos)

    }

    const dates = () => {

        if(date.get('start') == undefined || date.get('end') == undefined) navigate('/404')

    }

    const verifierDate = async () => {

        let results = await Annonces.verifierDate(id, date.get('start'), date.get('end'))

        if(results.success){
            
            setinfos(results)

        }else{

            setError(results.message)

        }

    }

    const reserver = async (e) => {

        e.preventDefault()

        let results = await Annonces.reserver(id, date.get('start'), date.get('end'), e.target)
        
        
        if(results.success){
            
            setinfos(results)
            
            setOnReserve(true)

        }else{

            setError(results.message)

        }

    }

    useEffect(() => {

        dates()

        verifierDate()

        getArticle()

        document.title = "Réserver une voiture - " + APP_NAME
        
    }, [])


    return (
        <div>

            <Header />

            <div className="single content">

                <div className="cc">
                    
                    {

                        article

                        ?

                            <div className='col-6 m0a'>
                                <div className="c-infos flex ai-center">

                                    <div className="img col-3">
                                        <img src={ PHOTOS_ROOT + 'full/' + photos[0].name + '.jpg' } alt={ article.title } className="col-10 br20"/>
                                    </div>

                                    <div className='col-7 p20 fz07'>

                                        <h1 className="mb20">{ article.titre}</h1>
                                        
                                        <div className="flex">

                                            <div className='flex jc-start ai-center'>

                                                {

                                                article.nbr_note > 0

                                                &&
                                                
                                                <div className="rating mr20">
                                                    <span className='icon ri-star-fill mr5'></span> 
                                                    <span className='p mr5'>{ article.note }</span>
                                                    <span>({ article.nbr_note })</span>
                                                </div>

                                                }


                                                <div className='place'>
                                                    <span className='icon ri-map'></span>
                                                    <span>{ article.adresse }</span>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className='price mt20'>{ article.prix } DA<span className="p"> / j</span></div>

                                    </div>

                                </div>

                                {error == '' && 
                                     <div>
                                        <p className={ onReserve ? 'bg3 p10 br10 mb20 mt10 cl1' : 'bg7 p10 br10 mb20 mt10'} >{infos.message}</p>
                                        <div className='flex-end'>
                                            <Link to='/mon-espace' className="btn2 bg7 cl2 p10">Voir les réservations</Link>
                                        </div>
                                    </div>
                                }

                                <div>

                                    {(error !== '') && <p className="error">{error}</p>
                                    }

                                    {
                                    
                                    (infos !== '' && onReserve == false)
                                    
                                    &&

                                        <div>
                                            
                                            <p className='mb20'>Réservation du véhicule pour <span className='fw5'>{ infos.nbr_days } jours</span></p>
                                            <p className='mb20'>Réservation du <span className='fw5'><Moment date={ date.get('start') } format={ 'DD / MM / YYYY' }></Moment></span> au <span className='fw5'><Moment date={ date.get('end') } format={ 'DD / MM / YYYY' }></Moment></span></p>

                                            <p className='mb20'>Montant total : <span className='fw5'>{ infos.total_price } DA</span></p>

                                            <div className="book">
                                                
                                                <form className="mt20 gla-form" onSubmit={ (e) => reserver(e) }>

                                                    <label>Laisser un message au propriétaire</label>

                                                    <textarea name='message' placeholder='Votre message ...'></textarea>

                                                    <p className='rem'>* Cliquez sur Réserver pour vous rediriger vers la page de paiement.</p>

                                                    <input type="submit" value="Réserver" className='btn bg3 cl1' />

                                                </form>

                                            </div>
                                        </div>

                                    }

                                </div>

                            </div>

                        :

                            <div className='flex jc-center mt50 mb50'>
                                <div className="loader"></div>
                            </div>

                    }

                </div>

            </div>

            <Footer />

        </div>
    )

}

export default Reserver