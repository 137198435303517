import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer } from '../../components/imports'

import { AnnonceCard } from '../../components/espace-membre'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Annonces from '../../class/Annonces'

import Func from '../../class/Func'

import { Menu } from '../../components/espace-membre';

function AnnoncesPage() {

    const navigate = useNavigate()
    
    const context = useContext(Context)

    const [flash, setFlash] = useState('')

    const [articles, setArticles] = useState([])

    const getArticles = async () => {

        let results = await Annonces.mesAnnonces()

        if(results.data){

            setArticles(results.data)

        }else{

            setArticles(results.message)

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        getArticles()

        document.title = "Mes annonces - " + APP_NAME

    }, [])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c">

                    <Menu data="annonces"/>

                    <div className="cont col-78">

                        <div className='flex ai-center mb30'>
                            <h2>Mes annonces</h2>
                            <Link to={'/mon-espace/publier'} className="btn bg3 cl1">Publier une annonce</Link>
                        </div>

                        {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}  

                        {

                            articles.length > 0

                            ?

                                typeof articles == 'string'

                                ?

                                <p>{ articles }</p>

                                :
                                
                                <div className="grid3 articles mb30">

                                    { articles.map(element => <AnnonceCard data={element} key={element.idA} />)}
                                    
                                </div>

                            :

                            <div className='flex jc-center mt50 mb50'>
                                <div className="loader"></div>
                            </div>

                        }

                    </div>
                </div>
            </div>

            <Footer/>

        </div>

    )

}

export default AnnoncesPage