import React, { useState, useEffect, useContext, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Header } from '../../components/imports'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Discussions from '../../class/Discussions'

import { DiscussionsCard, Message } from '../../components/espace-membre'

import { firebase_db } from '../../class/F_base'

import { collection, addDoc , onSnapshot, query, where, limit, orderBy } from 'firebase/firestore';

function Discussion() {

    const { id } = useParams()

    const navigate = useNavigate()

    const context = useContext(Context)

    const [discussion, setDiscussion] = useState(false)
    const [messages, setMessages]= useState([])

    const scroll = useRef()

    const getDiscussion = async () => {

        let results = await Discussions.discussion(id)

        if(results.success){
            
            setDiscussion(true)

            read_messages(results.discussion.id)

        }else{

            navigate('/404')

        }
    
    }

    const add_message = async (e) => {

        e.preventDefault()

        if(!discussion) return

        let message_input = e.target.message

        if (message_input.value.trim() === "") return

        let message = {
            id: id,
            sender: localStorage.getItem('idU'),
            msg: message_input.value,
            date: Date.now()
        }

        const docRef = await addDoc(collection(firebase_db, "messages"), message)
        
        message_input.value = ''
        
        message_input.focus()
        
        scroll.current.scrollIntoView({ behavior: "smooth" })
        
        if(docRef.id !== undefined) Discussions.envoyer_message(id)

    }

    const read_messages = async (id) => {

        //if(!discussion) return
        
        const q = query(collection(firebase_db, "messages"), where("id", "==", id), orderBy("date", "desc"), limit(15));

        onSnapshot(q, (querySnapshot) => {

            const messages_list = []

            querySnapshot.forEach((doc) => {
                
                messages_list.push({ ...doc.data(), id: doc.id })

            })

            if(messages_list.length == 0){

                setMessages("Aucun message pour l'instant, vous pouvez envoyer un message.")

            }else{
                
                let messages_list_reverse = messages_list.reverse()
                setMessages(messages_list_reverse)
                
            }

            setTimeout(() => {
                scroll.current.scrollIntoView({ behavior: "smooth" })
            }, 200);

        })

    }

    useEffect(() => {

        Func.session(context, navigate)

        getDiscussion()

        document.title = "Discussion - " + APP_NAME

    }, [id])


    return (

        <div>

            <Header />
                
            <div className="content dashboard">
                <div className="flex c">

                <DiscussionsCard />

                <div className="messages_box col-7">
                    
                    <div className='messages bg5'>
                        
                        {

                            messages.length == 0

                            ?

                            <div className='flex jc-center pt100'><div className='loader'></div></div>

                            :

                            typeof messages === 'string'

                            ?

                            <div className='flex jc-center pt100'>{ messages }</div>

                            :

                            messages.map(message => <Message data={message} key={ message.id }/>)

                        }

                        <span ref={scroll}></span>

                    </div>

                    <form className='messages_send bg5' method='POST' onSubmit={ (e) => add_message(e) } autoComplete="off">

                        <label className='flex'>
                            <input type='text' name='message' placeholder='Votre message ...' className='bg5'/>
                            <input type='submit' className='bg3 cl1' value="Envoyer"></input>
                        </label>

                    </form>

                </div>
                </div>
            </div>

        </div>

    )

}

export default Discussion