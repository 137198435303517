const WEBROOT = "https://ekriny.com/"
const API_ROOT = WEBROOT + "api/"

const PHOTOS_ROOT = WEBROOT + "image/annonces/"

const AVATAR_ROOT = WEBROOT + "image/profile/"
const AVATAR_DEFAULT = "/image/AVATAR_DEFAULT.jpg"

const APP_NAME = "Ekriny"

const MAPS_API_ROOT = "https://maps.googleapis.com/maps/api/js"
const MAPS_API_KEY = "AIzaSyAUPljZZPLsC9C1a59CiXzKHfqYGYqpOPU"

// API

const API_SECRET = "123123"

export { WEBROOT, API_ROOT, APP_NAME, API_SECRET, AVATAR_ROOT, AVATAR_DEFAULT, PHOTOS_ROOT, MAPS_API_ROOT, MAPS_API_KEY }